import { FC, MouseEvent, PropsWithChildren } from 'react';
import { ReactComponent as QuestionSVG } from 'assets/icons/question.svg';
import { InfoHintModal } from 'components/shared';
import { useOpen } from 'utils/hooks';
import { FirebaseAnalytics } from 'utils/services';

export interface InfoHintModalAnchorProps {
	className?: string;
	analyticsPage: string;
	analyticsButton: string;
}

export const InfoHintModalAnchor: FC<PropsWithChildren<InfoHintModalAnchorProps>> = ({
	className = '',
	children,
	analyticsButton,
	analyticsPage,
}) => {
	const { isOpen, handleClose, handleOpen } = useOpen();

	const handleOpenModal = (e: MouseEvent) => {
		FirebaseAnalytics.logEvent(analyticsPage, analyticsButton);
		e.stopPropagation();
		handleOpen();
	};

	if (!children) return null;

	return (
		<>
			<QuestionSVG className={className} onClick={handleOpenModal} />
			<InfoHintModal isOpen={isOpen} onClose={handleClose}>
				{children}
			</InfoHintModal>
		</>
	);
};
