import { ChangeEvent, FC, FocusEvent, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { AutocompleteInputChangeReason, Typography } from '@mui/material';
import { useAppDispatch } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { ValidationError } from 'yup';
import { CustomModal } from 'components/shared';
import {
	APARTMENT_ADDRESS_INITIAL_OPTIONS,
	APARTMENT_ADDRESS_TOUCHED,
	APARTMENT_ADDRESS_VALIDATION_ERRORS,
	APARTMENT_FIELD_CONFIG,
} from 'utils/constants';
import { ADD_ADDRESS_FIELD_NAME } from 'utils/enums';
import {
	getAddAppartmentByAddressTouchedFields,
	getErrorMessage,
	remapApartmentFlatsResponse,
	sortApartmentItemsResponse,
} from 'utils/helpers';
import { useDebounceState, useDevice, useOpen } from 'utils/hooks';
import { StreetsService } from 'utils/services';
import {
	ApartmentCityItem,
	ApartmentFlatItem,
	ApartmentHouseItem,
	ApartmentItem,
	ApartmentRegionItem,
	ApartmentStreetItem,
	IAddApartmentRequest,
} from 'utils/types';
import { createApartmentValidationSchema, createApartmentWithFlatValidationSchema } from 'utils/validation';
import { AddressAutocomplete } from './AddressAutocomplete';
import { ApartmentNativeAutocomplete } from './ApartmentNativeAutocomplete';

interface AddApartmentByAddressProps {
	data: IAddApartmentRequest;
	onChange: (data: IAddApartmentRequest) => void;
	setIsValid: (value: boolean) => void;
}

export const AddApartmentByAddress: FC<AddApartmentByAddressProps> = ({ data, onChange, setIsValid }) => {
	const dispatch = useAppDispatch();

	const { isMobile } = useDevice();

	const { isOpen, handleOpen, handleClose, handleDidPresent, isPresented } = useOpen();

	const [isLoading, setIsLoading] = useState({
		region: true,
		city: false,
		street: false,
		house: false,
		flat: false,
	});

	const [selectOptions, setSelectOptions] = useState<Record<string, any[]>>(APARTMENT_ADDRESS_INITIAL_OPTIONS);

	const { value: cityInput, setValue: setCityInput, debouncedValue: debouncedCity } = useDebounceState('');
	const { value: streetInput, setValue: setStreetInput, debouncedValue: debouncedStreet } = useDebounceState('');
	const { value: houseInput, setValue: setHouseInput, debouncedValue: debouncedHouse } = useDebounceState('');
	const { value: flatInput, setValue: setFlatInput, debouncedValue: debouncedFlat } = useDebounceState('');

	const [validationError, setValidationError] = useState(APARTMENT_ADDRESS_VALIDATION_ERRORS);
	const [touched, setTouched] = useState(APARTMENT_ADDRESS_TOUCHED);
	const [focusedFieldName, setFocusedFieldName] = useState<ADD_ADDRESS_FIELD_NAME | null>(null);

	const disabledFields = useMemo(
		() => ({
			region: isLoading.region,
			city: !data.region,
			street: !data.city,
			house: !data.street,
			flat: (!data.street && !data.house) || !selectOptions.flat.length,
		}),
		[data, isLoading.region, selectOptions.flat.length]
	);

	useEffect(() => {
		handleGetRegions();
	}, []);

	useLayoutEffect(() => {
		if (debouncedCity.length > 2 && data.region) {
			handleGetCitiesByTitle(data.region, debouncedCity);
		}
	}, [data.region, debouncedCity]);

	useLayoutEffect(() => {
		if (debouncedStreet.length > 2 && data.city) {
			handleGetStreetsByTitle(data.city, debouncedStreet);
		}
	}, [data.city, debouncedStreet]);

	useLayoutEffect(() => {
		if (debouncedHouse && data.street) {
			handleGetHouseByTitle(data.street, debouncedHouse);
		}
	}, [data.street, debouncedHouse]);

	useLayoutEffect(() => {
		if (data.house && debouncedFlat) {
			handleGetFlatsByHouse(data.house, debouncedFlat);
		}
	}, [data.house, debouncedFlat]);

	useEffect(() => {
		validateData(data);
	}, [data, selectOptions.flat, isLoading.flat]);

	const validateData = async (value: IAddApartmentRequest): Promise<void> => {
		try {
			let validatonSchema = createApartmentValidationSchema;
			if (selectOptions.flat.length || isLoading.flat) {
				validatonSchema = createApartmentWithFlatValidationSchema;
			}
			await validatonSchema.validate(value, { abortEarly: false });
			setValidationError(APARTMENT_ADDRESS_VALIDATION_ERRORS);
			setIsValid(true);
		} catch (error) {
			const errors: any = {};
			error.inner.forEach((err: ValidationError) => {
				errors[err.path as string] = err.message || '';
			});
			setIsValid(false);
			setValidationError(errors);
		}
	};

	const handleGetRegions = async () => {
		try {
			setIsLoading((prev) => ({ ...prev, region: true }));
			const { data } = await StreetsService.searchRegions({});
			setSelectOptions((prev) => ({ ...prev, region: data }));
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading((prev) => ({ ...prev, region: false }));
		}
	};

	const handleGetCitiesByTitle = async (region: ApartmentRegionItem, cityTitle: string | undefined = undefined) => {
		try {
			setSelectOptions((prev) => ({ ...prev, city: [] }));
			setIsLoading((prev) => ({ ...prev, city: true }));
			const { data } = await StreetsService.searchCities({ ...region, whereTitleLike: cityTitle });
			setSelectOptions((prev) => ({
				...prev,
				city: data,
			}));
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading((prev) => ({ ...prev, city: false }));
		}
	};

	const handleGetStreetsByTitle = async (city: ApartmentCityItem, streetTitle: string | undefined = undefined) => {
		try {
			setSelectOptions((prev) => ({ ...prev, street: [] }));
			setIsLoading((prev) => ({ ...prev, street: true }));
			const { data } = await StreetsService.searchStreets({ ...city, whereTitleLike: streetTitle });
			setSelectOptions((prev) => ({
				...prev,
				street: data,
			}));
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading((prev) => ({ ...prev, street: false }));
		}
	};

	const handleGetHouseByTitle = async (street: ApartmentStreetItem, houseTitle: string | undefined = undefined) => {
		try {
			setSelectOptions((prev) => ({ ...prev, house: [] }));
			setIsLoading((prev) => ({ ...prev, house: true }));
			const { data } = await StreetsService.searchHouses({ ...street, whereTitleLike: houseTitle });
			setSelectOptions((prev) => ({
				...prev,
				house: data.sort(sortApartmentItemsResponse),
			}));
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading((prev) => ({ ...prev, house: false }));
		}
	};

	const handleGetFlatsByHouse = async (house: ApartmentHouseItem, flatTitle: string | undefined = undefined) => {
		try {
			setIsLoading((prev) => ({ ...prev, flat: true }));
			const { data } = await StreetsService.searchFlats(flatTitle ? { ...house, whereTitleLike: flatTitle } : house);
			setSelectOptions((prev) => ({
				...prev,
				flat: data.length ? remapApartmentFlatsResponse(data) : prev.flat,
			}));
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading((prev) => ({ ...prev, flat: false }));
		}
	};

	const handleGetOptionLabel = (option: ApartmentItem) => option?.title ?? '';

	const handleBlurAutocomplete = (event: FocusEvent<HTMLInputElement>): void => {
		if (!isMobile) {
			setTouched({ ...touched, [event.target.name]: true });
		}
	};

	const handleChangeTouched = (name: string): void => {
		const touchedPayload = getAddAppartmentByAddressTouchedFields(touched, name);
		setTouched(touchedPayload);
	};

	const handleModalClose = (name?: string): void => {
		handleClose();
		if (name) {
			handleChangeTouched(name);
		}
	};

	const handleTouchEndMobile = (fieldName: ADD_ADDRESS_FIELD_NAME): void => {
		if (isMobile && !disabledFields[fieldName]) {
			setFocusedFieldName(fieldName);
			handleOpen();
		}
	};

	const handleChangeInput =
		(fn: (value: string) => void) => (_: unknown, value: string, reason: AutocompleteInputChangeReason) => {
			if (reason === 'input') {
				fn(value);
			} else if (reason === 'reset') {
				fn('');
			}
		};

	const onChangeMap: Record<string, (value: string) => void> = useMemo(
		() => ({
			[ADD_ADDRESS_FIELD_NAME.CITY]: setCityInput,
			[ADD_ADDRESS_FIELD_NAME.STREET]: setStreetInput,
			[ADD_ADDRESS_FIELD_NAME.HOUSE]: setHouseInput,
			[ADD_ADDRESS_FIELD_NAME.FLAT]: setFlatInput,
		}),
		[]
	);

	const handleChangeNativeInput = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		const handler = onChangeMap[name];

		if (handler) {
			handleChangeInput(handler)(null, value, value ? 'input' : 'reset');
		}
	};

	const handleResetSelectOptions = (fields: (keyof typeof APARTMENT_ADDRESS_INITIAL_OPTIONS)[]) => {
		setSelectOptions((prev) => ({
			...prev,
			...fields.reduce((acc, curr) => ({ ...acc, [curr]: APARTMENT_ADDRESS_INITIAL_OPTIONS[curr] }), {}),
		}));
	};

	const handleChangeApartmentData = async (value: ApartmentItem | null, name: string, isShouldCloseModal = false) => {
		if (typeof value !== 'object') return;
		let payload = { ...data };
		switch (name) {
			case ADD_ADDRESS_FIELD_NAME.REGION:
				if (data.region?.title === value?.title) {
					break;
				}
				payload = {
					...data,
					region: value,
					city: null,
					street: null,
					house: null,
					flat: null,
				};
				handleResetSelectOptions(['city', 'street', 'house', 'flat']);
				setCityInput('');
				setStreetInput('');
				setHouseInput('');
				setFlatInput('');
				break;
			case ADD_ADDRESS_FIELD_NAME.CITY:
				if (data.city?.title === value?.title) {
					break;
				}
				payload = {
					...data,
					city: value as ApartmentCityItem,
					street: null,
					house: null,
					flat: null,
				};
				handleResetSelectOptions(['street', 'house', 'flat']);
				setCityInput('');
				setStreetInput('');
				setHouseInput('');
				setFlatInput('');
				break;
			case ADD_ADDRESS_FIELD_NAME.STREET:
				if (data.street?.title === value?.title) {
					break;
				}
				payload = {
					...data,
					street: value as ApartmentStreetItem,
					house: null,
					flat: null,
				};
				handleResetSelectOptions(['house', 'flat']);
				setStreetInput('');
				setHouseInput('');
				setFlatInput('');
				break;

			case ADD_ADDRESS_FIELD_NAME.HOUSE:
				if (data.house?.title === value?.title) {
					break;
				}
				payload = {
					...data,
					house: value as ApartmentHouseItem,
					flat: null,
				};
				onChange(payload);
				handleResetSelectOptions(['flat']);

				if (isMobile && isShouldCloseModal) {
					handleModalClose();
				}

				handleChangeTouched(name);
				if (value) {
					await handleGetFlatsByHouse(value as ApartmentHouseItem);
				}
				setHouseInput('');
				setFlatInput('');
				break;

			case ADD_ADDRESS_FIELD_NAME.FLAT:
				payload = { ...data, flat: value as ApartmentFlatItem };
				setFlatInput('');
				break;

			default:
				break;
		}
		if (isShouldCloseModal && name !== ADD_ADDRESS_FIELD_NAME.HOUSE) {
			handleModalClose();
		}
		onChange(payload);
		handleChangeTouched(name);
	};

	const debouncedValues = useMemo(
		() => ({
			city: debouncedCity,
			flat: debouncedFlat,
			house: debouncedHouse,
			street: debouncedStreet,
			region: undefined,
		}),
		[debouncedCity, debouncedFlat, debouncedHouse, debouncedStreet]
	);

	return (
		<>
			<Typography variant="h5" textAlign="center" width="100%">
				Вкажіть вашу адресу
			</Typography>
			<AddressAutocomplete
				{...APARTMENT_FIELD_CONFIG.region}
				id="add-address-region"
				options={selectOptions.region}
				disabled={disabledFields.region}
				name={ADD_ADDRESS_FIELD_NAME.REGION}
				value={data.region ?? ''}
				loading={isLoading.region}
				isClear={!isMobile && !!data.region}
				getOptionLabel={handleGetOptionLabel}
				onChange={(_, value) => handleChangeApartmentData(value, ADD_ADDRESS_FIELD_NAME.REGION)}
				onBlur={handleBlurAutocomplete}
				onTouchEnd={() => handleTouchEndMobile(ADD_ADDRESS_FIELD_NAME.REGION)}
				isError={touched.region && !!validationError.region}
				helperText={touched.region ? validationError.region : ''}
				readOnly={isMobile}
			/>
			<AddressAutocomplete
				{...APARTMENT_FIELD_CONFIG.city}
				id="add-address-city"
				options={selectOptions.city}
				disabled={disabledFields.city}
				name={ADD_ADDRESS_FIELD_NAME.CITY}
				value={data.city ?? ''}
				loading={isLoading.city}
				isClear={!isMobile && (!!data.city || !!cityInput)}
				getOptionLabel={handleGetOptionLabel}
				onInputChange={!isMobile ? handleChangeInput(setCityInput) : undefined}
				onChange={(_, value) => handleChangeApartmentData(value, ADD_ADDRESS_FIELD_NAME.CITY)}
				onBlur={handleBlurAutocomplete}
				onTouchEnd={() => handleTouchEndMobile(ADD_ADDRESS_FIELD_NAME.CITY)}
				isError={touched.city && !!validationError.city}
				helperText={touched.city ? validationError.city : ''}
				readOnly={isMobile}
				portmoneLimit={10}
			/>
			<AddressAutocomplete
				{...APARTMENT_FIELD_CONFIG.street}
				id="add-address-street"
				options={selectOptions.street}
				disabled={disabledFields.street}
				name={ADD_ADDRESS_FIELD_NAME.STREET}
				value={data.street ?? ''}
				loading={isLoading.street}
				isClear={!isMobile && (!!data.street || !!streetInput)}
				getOptionLabel={handleGetOptionLabel}
				onInputChange={!isMobile ? handleChangeInput(setStreetInput) : undefined}
				onChange={(_, value) => handleChangeApartmentData(value, ADD_ADDRESS_FIELD_NAME.STREET)}
				onBlur={handleBlurAutocomplete}
				onTouchEnd={() => handleTouchEndMobile(ADD_ADDRESS_FIELD_NAME.STREET)}
				isError={touched.street && !!validationError.street}
				helperText={touched.street ? validationError.street : ''}
				readOnly={isMobile}
				portmoneLimit={10}
			/>
			<AddressAutocomplete
				{...APARTMENT_FIELD_CONFIG.house}
				id="add-address-house"
				options={selectOptions.house}
				disabled={disabledFields.house}
				name={ADD_ADDRESS_FIELD_NAME.HOUSE}
				value={data.house ?? ''}
				loading={isLoading.house}
				isClear={!isMobile && (!!data.house || !!houseInput)}
				getOptionLabel={handleGetOptionLabel}
				onInputChange={!isMobile ? handleChangeInput(setHouseInput) : undefined}
				onChange={(_, value) => handleChangeApartmentData(value, ADD_ADDRESS_FIELD_NAME.HOUSE)}
				onBlur={handleBlurAutocomplete}
				onTouchEnd={() => handleTouchEndMobile(ADD_ADDRESS_FIELD_NAME.HOUSE)}
				isError={touched.house && !!validationError.house}
				helperText={touched.house ? validationError.house : ''}
				readOnly={isMobile}
				portmoneLimit={10}
			/>
			<AddressAutocomplete
				{...APARTMENT_FIELD_CONFIG.flat}
				id="add-address-flat"
				options={selectOptions.flat}
				disabled={disabledFields.flat}
				name={ADD_ADDRESS_FIELD_NAME.FLAT}
				value={data.flat ?? ''}
				loading={isLoading.flat}
				isClear={!isMobile && (!!data.flat || !!flatInput)}
				getOptionLabel={handleGetOptionLabel}
				onInputChange={!isMobile ? handleChangeInput(setFlatInput) : undefined}
				onChange={(_, value) => handleChangeApartmentData(value, ADD_ADDRESS_FIELD_NAME.FLAT)}
				onBlur={handleBlurAutocomplete}
				onTouchEnd={() => handleTouchEndMobile(ADD_ADDRESS_FIELD_NAME.FLAT)}
				filterOptions={(options) => options}
				isError={touched.flat && !!validationError.flat}
				helperText={touched.flat ? validationError.flat : ''}
				readOnly={isMobile}
				portmoneLimit={10}
			/>
			<CustomModal
				isOpen={isOpen}
				onClose={() => handleModalClose(focusedFieldName as ADD_ADDRESS_FIELD_NAME)}
				onDidPresent={handleDidPresent}
				isFullHeight
			>
				{!!focusedFieldName && (
					<ApartmentNativeAutocomplete
						{...APARTMENT_FIELD_CONFIG[focusedFieldName as keyof typeof APARTMENT_FIELD_CONFIG]}
						onChange={(value, name) => handleChangeApartmentData(value, name, true)}
						value={data[focusedFieldName] ?? ''}
						name={focusedFieldName}
						options={selectOptions[focusedFieldName as ADD_ADDRESS_FIELD_NAME]}
						isLoading={isLoading[focusedFieldName]}
						isError={touched[focusedFieldName] && !!validationError[focusedFieldName]}
						helperText={touched[focusedFieldName] ? validationError[focusedFieldName] : ''}
						onInputChange={handleChangeNativeInput}
						isOpenModal={isPresented}
						onClear={() => handleChangeApartmentData(null, focusedFieldName, false)}
						debouncedValue={debouncedValues?.[focusedFieldName]}
						minLength={[ADD_ADDRESS_FIELD_NAME.CITY, ADD_ADDRESS_FIELD_NAME.STREET].includes(focusedFieldName) ? 3 : 1}
						portmoneLimit={
							[
								ADD_ADDRESS_FIELD_NAME.CITY,
								ADD_ADDRESS_FIELD_NAME.STREET,
								ADD_ADDRESS_FIELD_NAME.HOUSE,
								ADD_ADDRESS_FIELD_NAME.FLAT,
							].includes(focusedFieldName)
								? 10
								: undefined
						}
					/>
				)}
			</CustomModal>
		</>
	);
};
