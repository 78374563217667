import { FC, FormEvent, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMetersList, updateMeterValue } from 'store/meters/actions';
import { getMetersStateSelector } from 'store/meters/selectors';
import { showToast } from 'store/toastify/reducer';
import { CustomButton } from 'components/shared';
import { DEFAULT_GET_METERS_LIST_REQUST } from 'utils/constants';
import { getErrorMessage, getMetersListsByProviderType } from 'utils/helpers';
import { IGetMetersListRequest, IMeterDataResponse } from 'utils/types';
import { MetersListItem } from './MetersListItem';
import styles from './index.module.scss';

export const MetersList: FC = () => {
	const dispatch = useAppDispatch();

	const meters = useAppSelector(getMetersStateSelector);

	const [listReqBody, setListReqBody] = useState<IGetMetersListRequest | null>(null);
	const [metersList, setMetersList] = useState<
		{
			providerTypeId: number;
			list: IMeterDataResponse[];
		}[]
	>([]);
	const [metersUpdateValues, setMetersUpdateValues] = useState<IMeterDataResponse[]>([]);
	const [validationError, setValidationError] = useState<{ [key: string]: string }>({});
	const [touched, setTouched] = useState<{ [key: string]: boolean }>({});
	const [isValid, setIsValid] = useState(false);

	useEffect(() => {
		if (meters.apartmentAccountId && !meters.list.length) {
			setListReqBody({ ...DEFAULT_GET_METERS_LIST_REQUST, apartmentAccountId: meters.apartmentAccountId });
		}
	}, [meters.apartmentAccountId, meters.list.length]);

	useEffect(() => {
		if (listReqBody) {
			handleGetMetersList(listReqBody);
		}
	}, [listReqBody]);

	useEffect(() => {
		const metersList = getMetersListsByProviderType(meters.list);
		setMetersList(metersList);
		setMetersUpdateValues(meters.list);
	}, [meters.list]);

	useEffect(() => {
		const isValidPayload =
			Object.values(validationError).every((item) => !item) &&
			metersUpdateValues.some((item) => item.currentValue && item.currentValue >= item.previousValue) &&
			metersUpdateValues.some((item) => {
				const defaultCurrentValue = meters.list.find(
					(meterItem) =>
						item.indexFromProvider === meterItem.indexFromProvider && item.serviceId === meterItem.serviceId
				);
				return defaultCurrentValue?.currentValue !== item.currentValue;
			});
		setIsValid(isValidPayload);
	}, [validationError, metersUpdateValues]);

	const handleGetMetersList = async (reqBody: any): Promise<void> => {
		try {
			await dispatch(getMetersList(reqBody)).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleChangeMeter = (indexFromProvider: string, value: number | null): void => {
		const payload = metersUpdateValues.map((item) =>
			item.indexFromProvider === indexFromProvider ? { ...item, currentValue: value } : item
		);
		setMetersUpdateValues(payload);
		validateData(indexFromProvider, value);
	};

	const validateData = (indexFromProvider: string, value: number | null): void => {
		const prevValue = metersList
			.map((item) => item.list)
			.flat()
			.find((item) => item.indexFromProvider === indexFromProvider);
		if (prevValue) {
			setValidationError({
				...validationError,
				[indexFromProvider]:
					value === null || value >= prevValue.previousValue
						? ''
						: 'Значення має бути більшим за попереднє або дорівнювати йому',
			});
		}
	};

	const handleInputBlur = (indexFromProvider: string): void => {
		setTouched({ ...touched, [indexFromProvider]: true });
	};

	const handleUpdateMeters = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
		try {
			event.preventDefault();
			const dataForUpdate = metersUpdateValues.filter((item) => !!item.currentValue);
			await dispatch(updateMeterValue({ dataForUpdate })).unwrap();
			dispatch(showToast({ message: 'Показники успішно подані', color: 'success' }));
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const getValidationError = (indexFromProvider: string) => ({
		error: !!validationError[indexFromProvider] && touched[indexFromProvider],
		helperText: touched[indexFromProvider] ? validationError[indexFromProvider] : '',
	});

	return (
		<form onSubmit={handleUpdateMeters}>
			{metersList.length ? (
				<div className={styles.content}>
					{metersList.map((item) => (
						<MetersListItem
							key={item.providerTypeId}
							list={item.list}
							onChange={handleChangeMeter}
							onBlur={handleInputBlur}
							getValidationError={getValidationError}
						/>
					))}
					<CustomButton type="submit" label="Надіслати показники" disabled={!isValid} />
				</div>
			) : (
				!meters.isLoading &&
				!meters.list.length && (
					<>
						<Typography variant="body1" textAlign="center" marginTop="var(--spacing-5)">
							Інформацію щодо лічильників ми отримуємо від комунальних підприємств.{' '}
						</Typography>
						<Typography variant="body1" textAlign="center">
							Зараз доступні більшість лічильників для м. Харкова, але ми працюємо над розширенням функціоналу
						</Typography>
					</>
				)
			)}
		</form>
	);
};
