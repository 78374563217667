import { FC, FocusEvent, KeyboardEvent, useEffect, useState } from 'react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { IonSpinner } from '@ionic/react';
import {
	CircularProgress,
	FormControl,
	FormHelperText,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Typography,
} from '@mui/material';
import { getProvidersList } from 'store/bill/actions';
import { getProvidersListStateSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ValidationError } from 'yup';
import { IAddApartmentRequest } from 'utils/types';
import { apartmentProviderIdValidationSchema } from 'utils/validation';
import styles from './index.module.scss';

interface AddApartmentByProviderIdProps {
	data: IAddApartmentRequest;
	onChange: (data: IAddApartmentRequest) => void;
	setIsValid: (value: boolean) => void;
	onSubmit: (e: KeyboardEvent) => void;
}

export const AddApartmentByProviderId: FC<AddApartmentByProviderIdProps> = ({
	data,
	onChange,
	setIsValid,
	onSubmit,
}) => {
	const dispatch = useAppDispatch();

	const providers = useAppSelector(getProvidersListStateSelector);

	const [touched, setTouched] = useState<Partial<IAddApartmentRequest>>({});
	const [validationError, setValidationError] = useState<Partial<IAddApartmentRequest>>({});

	useEffect(() => {
		if (!providers.list.length) {
			dispatch(getProvidersList());
		}
	}, [providers.list.length]);

	const validateData = async (value: Partial<IAddApartmentRequest>): Promise<void> => {
		try {
			await apartmentProviderIdValidationSchema.validate(value, { abortEarly: false });
			setValidationError({});
			setIsValid(true);
		} catch (error) {
			const errors: any = {};
			error.inner.forEach((err: ValidationError) => {
				errors[err.path as string] = err.message || '';
			});
			setValidationError(errors);
			setIsValid(false);
		}
	};

	const handleChangeProviderAccountId = ({ value }: NumberFormatValues): void => {
		const payload: IAddApartmentRequest = { ...data, providerAccountId: value };
		onChange(payload);
		validateData(payload);
	};

	const handleChangeProviderType = (event: SelectChangeEvent<string>): void => {
		const payload: IAddApartmentRequest = { ...data, providerId: event.target.value };
		onChange(payload);
		validateData(payload);
	};

	const handleBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
		setTouched({ ...touched, [event.target.name]: true });
		validateData(data);
	};

	return (
		<>
			<Typography variant="h5">Вкажіть ваш особовий рахунок (о/р)</Typography>
			<FormControl error={touched && !!validationError.providerId}>
				<InputLabel id="select-label">Оберіть постачальника послуг</InputLabel>
				<Select
					name="providerId"
					labelId="select-label"
					value={data.providerId ?? ''}
					onChange={handleChangeProviderType}
					onBlur={handleBlur}
					IconComponent={
						providers.isLoading
							? () => {
									return null;
							  }
							: undefined
					}
					endAdornment={
						providers.isLoading ? (
							<InputAdornment position="end">
								<CircularProgress color="inherit" size={20} />
							</InputAdornment>
						) : undefined
					}
				>
					{providers.isLoading ? (
						<MenuItem className={styles.loader} disabled>
							<IonSpinner name="dots" />
						</MenuItem>
					) : (
						providers.list.map((item) => (
							<MenuItem key={item.id} value={item.name}>
								{item.title}
							</MenuItem>
						))
					)}
				</Select>
				{!!touched.providerId && !!validationError.providerId && (
					<FormHelperText>{validationError.providerId}</FormHelperText>
				)}
			</FormControl>

			<NumericFormat
				onKeyDown={onSubmit}
				allowNegative={false}
				customInput={TextField}
				value={data.providerAccountId}
				name="providerAccountId"
				defaultValue=""
				onValueChange={handleChangeProviderAccountId}
				onBlur={handleBlur}
				placeholder="0000000000"
				inputProps={{ inputMode: 'numeric', autoComplete: 'off' }}
				helperText={touched.providerAccountId && validationError.providerAccountId}
				error={!!touched.providerAccountId && !!validationError.providerAccountId}
				disabled={data.providerId === null}
			/>
		</>
	);
};
