export enum STREET_TYPE {
	STREET = 'ВУЛИЦЯ',
	BYSTREET = 'ПРОВУЛОК',
}

export enum ADD_ADDRESS_FIELD_NAME {
	REGION = 'region',
	CITY = 'city',
	STREET = 'street',
	HOUSE = 'house',
	FLAT = 'flat',
}

export enum ADD_APARTMENT_VARIANT {
	ADDRESS = 'ADDRESS',
	APARTMENT_ACCOUNT_ID = 'apartmentAccountId',
	PROVIDER_ACCOUNT_ID = 'providerAccountId',
}
