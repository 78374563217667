export const FIREBASE_EVENT_ANALYTICS_PAGE = {
	AUTH: {
		LOGIN: 'authLogin',
		CHECK_PHONE: 'authCheckPhone',
		PASSWORD_RESTORE_VERIFY: 'authPwdRstrVfy',
		PASSWORD_RESTORE: 'authPwdRstr',
		SET_PASSWORD: 'authSetPwd',
		VERIFY_USER: 'authVfyUser',
		UPDATE_PROFILE: 'authUpdProfile',
		ONBOARDING: 'authOnboard',
		VERIFY_DEVICE: 'authVfyDevice',
	},
	PUBLIC: {
		RECEIPT: 'publicReceipt',
	},

	PROFILE: {
		HOME: 'home',
		SETTINGS: 'settings',
		ADD_ADDRESS: 'addAddress',
		SINGLE_RECEIPT: 'singleRec',
		SERVICES: 'services',
		SERVICES_BILLS_REPORT: 'servicesBillsArchiveReport',
		SERVICES_BILLS_LIST_REPORT: 'servicesBillsAListArchive',
	},
	RADABANK: {
		INFO_BEGIN: 'RBauthInfoBegin',
		INFO_DIYA_BEGIN: 'RBauthInfoDiyaBegin',
		CONFIRM_PHONE: 'RBauthConfirmPhone',
		DOCUMENTS_SUBMISSION: 'RBauthDocumentsSubmission',
		SET_PHOTO: 'RBauthSetPhoto',
		INFO_ANKETA: 'RBauthInfoAnketa',
		ZAYAVA: 'RBauthZayavaAndContract',
		SET_SIGN: 'RBauthSetSign',
		DOCUMENTS_SIGNING: 'RBauthDocumentsSigning',
		INTERNAL_AUTH: 'RBauthInternal',
		STATUS: 'RBauthStatus',
		OTHER_DEVICE: 'RBauthOtherDevice',

		CREATE_CARD: 'RBcreateCard',
		ACTIVATE_RB_USER: 'RBactivateUser',
		CREATE_CARD_SUBMIT: 'RBCreateCardSubmission',
		OPERATION: 'RBOperation',
		OPERATION_TO_CARD: 'RBOperationToCard',
		OPERATION_TO_CARD_DETAILS: 'RBOperationToCardDetails',
		OPERATION_TO_ACCOUNT: 'RBOperationToAccount',
		OPERATION_TO_ACCOUNT_DETAILS: 'RBOperationToAccountDetails',
		OPERATION_TO_BUDGET: 'RBOperationToBudget',
		OPERATION_TO_BUDGET_DETAILS: 'RBOperationToBudgetDetails',
		OPERATION_BANNER: 'RBOperationBanner',
		CARD_TOP_UP: 'RBCardAddFunds',
		CARD_MAIN: 'RBCardMain',
		SETTINGS: 'RBSettings',
		ORDER_PLASTIC_CARD: 'RBOrderPlasticCard',
		ORDER_PLASTIC_CARD_STATUS: 'RBOrderPlasticCardStatus',
		CARD_OVERDRAFT: 'RBCardOverdraft',
		CARD_OVERDRAFT_DETAILS: 'RBCardOverdraftDetails',
		CARD_OVERDRAFT_BANNER: 'RBCardOverdraftBanner',
		BLOCK_CARD: 'RBBlockCard',
		UNBLOCK_CARD: 'RBUnblockCard',
		PIN_CODE_SMS: 'RBPinCodeSms',
		PIN_CODE_CHANGE_SMS: 'RBPinCodeChangeSms',
		DETAILS_AND_DOCUMENTS: 'RBDetailsAndDocs',
		SECURE_SETTINGS: 'RBSecureSettings',
		SMS_INFO: 'RBsmsInfo',
		TRANSACTION_DETAILS: 'RBOperationDetails',
	},
	PERMISSIONS: {
		PUSH_NOTIFICATIONS: 'pushNotificationsAccess',
		CAMERA: 'cameraAccess',
	},
};

export const FIREBASE_EVENT_ANALYTICS_BUTTON = {
	// auth
	LOGIN: 'login',
	SIGN_UP: 'signUp',
	CONFIRM_PHONE: 'confirmPhone',
	SET_PASSWORD: 'setPwd',
	RESTORE_PASSWORD: 'restorePwd',
	CONFIRM_PASSWORD: 'confirmPwd',
	CURRENT_PASSWORD: 'currentPwd',
	VERIFY_USER: 'verifyUser',
	RESEND_OTP: 'resendOTP',
	CONFIRM_OTP: 'confirmOTP',
	CONFIRM_DEVICE: 'confirmDevice',
	UPDATE_PROFILE: 'updateProfile',
	// common
	APARTMENT_VARIANT_BY_ADDRESS: 'aptByAddr',
	APARTMENT_VARIANT_BY_ADDRESS_SUBMIT: 'aptByAddrSbmt',
	APARTMENT_VARIANT_BY_HOST_ID: 'aptByHostId',
	APARTMENT_VARIANT_BY_HOST_ID_SUBMIT: 'aptByHostSbmt',
	APARTMENT_VARIANT_BY_PROVIDER_ACCOUNT_ID: 'aptByAccId',
	APARTMENT_VARIANT_BY_PROVIDER_ACCOUNT_ID_SUBMIT: 'aptByAccIdSbmt',
	ADD_APARTMENT_SKIP: 'addAptSkip',
	GO_BACK: 'goBack',
	GO_HOME: 'goHome',
	PRIVACY_POLICY: 'privacyPolicy',
	SLIDER_BUTTON_NEXT: 'sliderBtnNext',
	SLIDER_BUTTON_PREV: 'sliderBtnPrev',
	CONTINUE: 'continue',
	SKIP: 'skip',
	DOWNLOAD: 'download',
	CHANGE_PERIOD: 'chnagePeriod',
	CREATE_CARD_RADABANK: 'createCardRB',
	ADD_CARD: 'addCard',
	OPEN_QR: 'qrScanner',
	ADD_ADDRESS: 'addApt',
	CHANGE_TAB: 'changeTab',
	NEXT: 'next',
	CREATE: 'create',
	UPDATE: 'update',
	BLOCK: 'block',
	UNBLOCK: 'unblock',
	SEND: 'send',
	ADD_PHONE: 'addPhone',
	TURN_ON: 'turnOn',
	CLOSE: 'close',
	ALLOW: 'allow',

	HOST_ID: 'questionHostId',

	// payments
	CREATE_PAYMENT: 'createPayment',
	PAY_BY_CARD: 'payCard',
	PAY_BY_GOOGLE: 'payGoogle',
	PAY_BY_APPLE: 'payApple',
	PAY_BY_OTHER_CARD: 'payOtherCard',

	// Single receipt
	SR_DELETE_ADRESS: 'deleteApt',
	SR_CHECK_PAYMENT_ARCHIVE: 'checkPayArchive',
	SR_CLICK_ADD_SERVICE: 'clickAddService',
	SR_ADD_SERVICE: 'addService',
	QUESTION_ADD_SERVICE: 'questionAddService',
	SR_CHOOSE_SERVICE_CATEGORY: 'chooseServiceCat',
	SR_CHOOSE_SERVICE: 'chooseService',
	MENU: 'menu',
	QUESTION_PROVIDER_DATA: 'questionWaitData',
	QUESTION_EMPTY_SR: 'questionEmptyReceipt',

	// services
	CATEGORIES: 'categories',
	SERVICES: 'services',

	// settings
	DELETE_ACCOUNT: 'deleteAccount',
	SUPPORT: 'support',
	BIOMETRY: 'biometry',
	PUSH_NOTIFICATIONS: 'pushNotifications',
	CHANGE_PASSWORD: 'changePassword',

	// radabank
	GO_TO_DIYA: 'goToDiya',
	PUBLIC_CONTRACT: 'publicContract',
	ZAYAVA: 'zayavaZgoda',
	ZAYAVA_PRO_VIDKRYTTIA: 'zayavaProVidkrytia',
	ADD_RULE: 'addRule',
	COPY_CARD_NUMBER: 'copyCardNumber',
	GET_CVV: 'getCVV',
	FLIP_CARD_FRONT: 'showCardFront',
	FLIP_CARD_BACK: 'showCardBack',
	TOP_UP_MOBILE_FIRST: 'topUpMobileFirstOperation',
	ADD_PHONE_CONTACTS: 'addPhoneFromContacts',
	ADD_PHONE_MANUAL: 'addPhoneManually',
	CREDIT_LIMIT: 'creditLimit',
	SHOW_STATEMENT: 'statement',
	VIEW_NBU_LIMITS: 'nbuLimits',
};
