import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Form, Formik } from 'formik';
import {
	getRadabankCurrentCardSelector,
	getUserProfileSelector,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import {
	convertUAHToCoins,
	getErrorMessage,
	getIbanOperationInitialValues,
	getTransactionParamFromSearch,
} from 'utils/helpers';
import { FirebaseAnalytics, RadabankService } from 'utils/services';
import { IRadabankTransferCreatePaymentRequest, IRadabankTransferFeeResponse } from 'utils/types';
import { radabankCardCreateTransferPaymentValidationSchema } from 'utils/validation';
import { FormContent } from './FormContent';
import { OperationDetails } from './OperationDetails';
import styles from './index.module.scss';

export const RadabankOperationAccount: FC = () => {
	const dispatch = useAppDispatch();

	const { search } = useLocation();

	const profile = useAppSelector(getUserProfileSelector);
	const cardData = useAppSelector(getRadabankCurrentCardSelector);

	const [initialValues, setInitialValues] = useState<
		IRadabankTransferCreatePaymentRequest & { isResident: boolean; isIPN: true }
	>(() => {
		const transaction = getTransactionParamFromSearch(search);

		const values = getIbanOperationInitialValues(transaction);

		return {
			...values,
			isResident: true,
			isIPN: true,
		};
	});
	const [isShowDetails, setIsShowDetails] = useState(false);
	const [feeData, setFeeData] = useState<IRadabankTransferFeeResponse | null>(null);
	const [transferPaymentData, setTransferPaymentData] = useState<IRadabankTransferCreatePaymentRequest | null>(null);

	useEffect(() => {
		if (profile && cardData) {
			setInitialValues({ ...initialValues, userId: profile.userId, cardid: cardData.id });
		}
	}, [profile, cardData]);

	const handleSubmit = async (values: IRadabankTransferCreatePaymentRequest) => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.OPERATION_TO_ACCOUNT,
				FIREBASE_EVENT_ANALYTICS_BUTTON.NEXT
			);
			dispatch(setAppLoading(true));
			const dto = {
				...values,
				innrec: values.innrec || undefined,
				namerec: values.namerec || undefined,
			};
			const response = await RadabankService.getTransferFee({
				...dto,
				amount: `${convertUAHToCoins(values.amount)}`,
			});
			setFeeData(response.data);
			setTransferPaymentData(dto);
			setIsShowDetails(true);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	return (
		<div className={styles.content}>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validateOnMount
				validationSchema={radabankCardCreateTransferPaymentValidationSchema}
			>
				<Form>
					<FormContent />
				</Form>
			</Formik>
			{isShowDetails && !!transferPaymentData && !!feeData && (
				<OperationDetails
					feeData={feeData}
					transferPaymentData={transferPaymentData}
					onClickBack={() => setIsShowDetails(false)}
				/>
			)}
		</div>
	);
};
