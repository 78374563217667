import DocumentSVG from 'assets/icons/document-text.svg';
import HostLogoSVG from 'assets/icons/host-icon.svg';
import HouseSVG from 'assets/icons/House.svg';
import { HostIdInfoModalContent } from 'components/Apartment/HostIdInfoModalContent';
import { ADD_APARTMENT_VARIANT } from 'utils/enums';
import { IAddAppartmentAddressGeneric, IAppartmentVariantItem } from 'utils/types/appartment-variant.interface';
import { FIREBASE_EVENT_ANALYTICS_BUTTON } from './firebase-event-analytics';

export const ADD_APARTMENT_VARIANT_LIST: IAppartmentVariantItem[] = [
	{
		title: 'За допомогою адреси',
		value: ADD_APARTMENT_VARIANT.ADDRESS,
		icon: HouseSVG,
	},
	{
		title: 'За допомогою HOST ID',
		value: ADD_APARTMENT_VARIANT.APARTMENT_ACCOUNT_ID,
		icon: HostLogoSVG,
		children: <HostIdInfoModalContent />,
		detailsAnalyticsKey: FIREBASE_EVENT_ANALYTICS_BUTTON.HOST_ID,
	},
	{
		title: 'За допомогою особового рахунку (o/p)',
		value: ADD_APARTMENT_VARIANT.PROVIDER_ACCOUNT_ID,
		icon: DocumentSVG,
	},
];

export const ADD_APARTMENT_VARIANT_LIST_MOBILE: IAppartmentVariantItem[] = [
	{
		title: 'За допомогою адреси',
		value: ADD_APARTMENT_VARIANT.ADDRESS,
		icon: HouseSVG,
	}
];


export const APARTMENT_ADDRESS_INITIAL_OPTIONS = {
	region: [],
	city: [],
	street: [],
	house: [],
	flat: [],
};

export const APARTMENT_ADDRESS_VALIDATION_ERRORS: IAddAppartmentAddressGeneric<string> = {
	region: '',
	city: '',
	street: '',
	house: '',
	flat: '',
};

export const APARTMENT_ADDRESS_TOUCHED: IAddAppartmentAddressGeneric<boolean> = {
	region: false,
	city: false,
	street: false,
	house: false,
	flat: false,
};

export const APARTMENT_ACCOUNT_ID_CHARS_LENGTH = 10;

export const APARTMENTS_MAX_COUNT = 9999;

export const NULL_FLAT_PLACEHOLDER = 'Приватний будинок';

export const KHARKIV_REGION_NAME = 'М. ХАРКІВ ТА ОБЛАСТЬ';
