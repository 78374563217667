import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { Form, Formik } from 'formik';
import {
	getRadabankCurrentCardSelector,
	getUserProfileSelector,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import * as Yup from 'yup';
import {
	FIREBASE_EVENT_ANALYTICS_BUTTON,
	FIREBASE_EVENT_ANALYTICS_PAGE,
	RADABANK_MAX_ALLOWED_TRANSACTION_AMOUNT,
	RADABANK_MIN_ALLOWED_TRANSACTION_AMOUNT,
} from 'utils/constants';
import { VALIDATION_ERROR } from 'utils/enums';
import {
	convertCoinsToUAH,
	convertUAHToCoins,
	getCardOperationInitialValues,
	getErrorMessage,
	getTransactionParamFromSearch,
	validateAmountLimits,
} from 'utils/helpers';
import { FirebaseAnalytics, RadabankService } from 'utils/services';
import {
	BooleanOrNull,
	IRadabankCreatePaymentRequest,
	IRadabankFeeResponse,
	IRadabankGetP2PAmountResponse,
	TypeOrNull,
} from 'utils/types';
import { radabankCardCreateCardPaymentValidationSchema } from 'utils/validation';
import { FormContent } from './FormContent';
import { OperationDetails } from './OperationDetails';
import styles from './index.module.scss';

export const RadabankOperationCard: FC = () => {
	const dispatch = useAppDispatch();

	const { search } = useLocation();

	const profile = useAppSelector(getUserProfileSelector);
	const cardData = useAppSelector(getRadabankCurrentCardSelector);

	const [initialValues, setInitialValues] = useState<IRadabankCreatePaymentRequest>(() => {
		const transaction = getTransactionParamFromSearch(search);

		return getCardOperationInitialValues(transaction);
	});
	const [isShowDetails, setIsShowDetails] = useState(false);

	const [feeData, setFeeData] = useState<IRadabankFeeResponse | null>(null);
	const [cardPaymentData, setCardPaymentData] = useState<IRadabankCreatePaymentRequest | null>(null);
	const [P2PAmount, setP2PAmount] = useState<TypeOrNull<IRadabankGetP2PAmountResponse>>(null);
	const [isRadabankCard, setIsRadabankCard] = useState<BooleanOrNull>(null);

	useEffect(() => {
		if (profile && cardData) {
			setInitialValues({ ...initialValues, userId: profile.userId, cardid: cardData.id });
		}
	}, [profile, cardData]);

	useEffect(() => {
		if (profile?.userId && !P2PAmount && isRadabankCard !== null) {
			handleGetP2PAmount(profile.userId);
		}
	}, [profile?.userId, isRadabankCard]);

	const handleGetP2PAmount = async (userId: string) => {
		try {
			dispatch(setAppLoading(true));
			const { data } = await RadabankService.getAmountP2PTransfer({ userId });
			setP2PAmount(data);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleSubmit = async (values: IRadabankCreatePaymentRequest) => {
		try {
			FirebaseAnalytics.logEvent(
				FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.OPERATION_TO_CARD,
				FIREBASE_EVENT_ANALYTICS_BUTTON.NEXT
			);
			dispatch(setAppLoading(true));
			const response = await RadabankService.getCardFee({ ...values, amount: `${convertUAHToCoins(values.amount)}` });
			setFeeData(response.data);
			setCardPaymentData({ ...values, amount: `${convertUAHToCoins(values.amount)}` });
			setIsShowDetails(true);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const validationSchema = useMemo(() => {
		if (!P2PAmount || isRadabankCard !== false) return radabankCardCreateCardPaymentValidationSchema;

		const availableAmount = convertCoinsToUAH(P2PAmount.amountrest);

		const maxAmount =
			availableAmount > RADABANK_MAX_ALLOWED_TRANSACTION_AMOUNT
				? RADABANK_MAX_ALLOWED_TRANSACTION_AMOUNT
				: availableAmount;

		const amountValidation = Yup.string()
			.required(VALIDATION_ERROR.REQUIRED)
			.test('less-than-nbu-limit', 'Перевищує ліміти НБУ', (value) =>
				validateAmountLimits(value, maxAmount, RADABANK_MIN_ALLOWED_TRANSACTION_AMOUNT)
			);
		return Yup.object().shape({
			...radabankCardCreateCardPaymentValidationSchema.fields,
			amount: amountValidation,
		});
	}, [P2PAmount, isRadabankCard]);

	return (
		<div className={styles.content}>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validateOnMount
				validationSchema={validationSchema}
			>
				<Form>
					<FormContent
						p2pLimits={P2PAmount}
						isRadabankCard={isRadabankCard}
						onChangeIsRadabankCard={setIsRadabankCard}
					/>
				</Form>
			</Formik>
			{isShowDetails && !!cardPaymentData && !!feeData && (
				<OperationDetails
					feeData={feeData}
					cardPaymentData={cardPaymentData}
					onClickBack={() => setIsShowDetails(false)}
				/>
			)}
		</div>
	);
};
