import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
	checkIsAmountServiceField,
	convertCoinsInSingleReceiptResponse,
	convertCoinsToUAH,
	convertUAHToCoins,
	getErrorResponse,
	getReceiptRequestBody,
} from 'utils/helpers';
import { BillService } from 'utils/services';
import {
	IAddApartmentAccountRequest,
	IAddOtherServiceToSingleReceipt,
	IAddServiceFieldValueRequest,
	IApartmentListResponse,
	IApartmentResponse,
	IBillsListRequest,
	IBillsReportResponse,
	IDeleteOtherServiceFromSingleReceipt,
	IPaginatedRequest,
	IPaymentResponse,
	IReceiptPayRequest,
	IReceiptRequest,
	IReceiptResponse,
	IRemoveApartmentRequest,
	IServicesCategoriesRequest,
	IUpdateOtherServiceFieldsValues,
	IUpdateServiceFieldValueRequest,
	ServicesCategoriesResponse,
	ServicesRequest,
	ServicesResponse,
} from 'utils/types';

export const addApartment = createAsyncThunk(
	'billSlice/addApartment',
	async (reqBody: IAddApartmentAccountRequest, { rejectWithValue }) => {
		try {
			await BillService.addApartment(reqBody);
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getApartmentListByAccountIds = createAsyncThunk(
	'billSlice/getApartmentListByAccountIds',
	async ({ apartmentAccountIds, userId }: { apartmentAccountIds: number[]; userId: string }, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IReceiptResponse>[] = await Promise.all(
				apartmentAccountIds.map(async (id) => BillService.getSingleReceipt(getReceiptRequestBody(id, userId)))
			);
			const data = response.map(convertCoinsInSingleReceiptResponse);

			return data;
		} catch (error) {
			rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getApartmentList = createAsyncThunk(
	'billSlice/getApartmentList',
	async (userId: string, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IApartmentListResponse> = await BillService.getApartmentList(userId);
			const listApartment = response.data.tableData.map((item: IApartmentResponse) => {
				const { singleReceipt, ...rest } = item;
				return {
					singleReceipt: singleReceipt
						? {
								...singleReceipt,
								sumBalance: convertCoinsToUAH(singleReceipt.sumBalance),
						  }
						: singleReceipt,
					...rest,
				} as IApartmentResponse;
			});
			return { listApartment, parsingInfo: response.data.parsingInfo };
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const removeApartment = createAsyncThunk(
	'billSlice/removeApartment',
	async (reqBody: IRemoveApartmentRequest, { rejectWithValue }) => {
		try {
			await BillService.removeApartment(reqBody);
			return reqBody.apartmentAccountId;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getSingleReceipt = createAsyncThunk(
	'billSlice/getSingleReceipt',
	async (reqBody: IReceiptRequest, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IReceiptResponse> = await BillService.getSingleReceipt(reqBody);
			const payload = convertCoinsInSingleReceiptResponse(response);

			return payload;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const paySingleReceipt = createAsyncThunk(
	'billSlice/paySingleReceipt',
	async (reqBody: IReceiptPayRequest, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IPaymentResponse[]> = await BillService.paySingleReceipt(reqBody);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getServicesCategories = createAsyncThunk(
	'billSlice/getServicesCategories',
	async (reqBody: IServicesCategoriesRequest, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<ServicesCategoriesResponse> = await BillService.getServicesCategories(reqBody);
			const tableData = response.data.tableData.map((item) => {
				const startIndex = item.image?.lastIndexOf('/') ?? 0;
				const endIndex = item.image?.lastIndexOf('_');
				const analyticsName = item.image?.slice(startIndex + 1, endIndex);
				return { ...item, analyticsName };
			});

			return { ...response.data, tableData };
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getServicesByCategory = createAsyncThunk(
	'billSlice/getServicesByCategory',
	async (reqBody: ServicesRequest, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<ServicesResponse> = await BillService.getServicesByCategory(reqBody);
			return response.data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const addSingleReceiptOtherService = createAsyncThunk(
	'billSlice/addSingleReceiptOtherService',
	async (data: IAddOtherServiceToSingleReceipt, { rejectWithValue }) => {
		try {
			const convertedToUAHFieldsValues: (IUpdateServiceFieldValueRequest | IAddServiceFieldValueRequest)[] =
				data.fieldsRequestData.map((item) => ({
					...item,
					value: checkIsAmountServiceField(item) ? convertUAHToCoins(item.value).toString() : item.value,
				}));
			const fieldsValuesRequestPromises = convertedToUAHFieldsValues.map((item) =>
				BillService.addServiceFieldValue(item as IAddServiceFieldValueRequest)
			);
			await Promise.all(fieldsValuesRequestPromises);
			await BillService.addServiceToReceipt(data.serviceRequestData);
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const updateSingleReceiptOtherServiceFieldsValues = createAsyncThunk(
	'billSlice/updateSingleReceiptOtherServiceFieldsValues',
	async (data: IUpdateOtherServiceFieldsValues, { rejectWithValue }) => {
		try {
			const updateUserValuesPromises = data.fieldsValues.map((item) =>
				BillService.updateServiceFieldValue({
					...item,
					value: checkIsAmountServiceField(item) ? convertUAHToCoins(item.value).toString() : item.value,
				})
			);
			await Promise.all(updateUserValuesPromises);
			return data;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const deleteSingleReceiptOtherService = createAsyncThunk(
	'billSlice/deleteSingleReceiptOtherService',
	async (data: IDeleteOtherServiceFromSingleReceipt, { rejectWithValue }) => {
		try {
			const deleteUserValuesPromises = data.fieldsRequestData.map((item) =>
				BillService.deleteServiceFieldValue({ id: item.id })
			);
			await Promise.all(deleteUserValuesPromises);
			await BillService.deleteServiceFromReceipt(data.serviceRequestData);
			return data.serviceRequestData.serviceId;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getBillsList = createAsyncThunk(
	'billSlice/getBillsList',
	async (reqBody: IPaginatedRequest<IBillsListRequest>, { rejectWithValue }) => {
		try {
			const response = await BillService.getBillsList(reqBody);
			return {
				...response.data,
				tableData: response.data.tableData.map((item) => ({
					...item,
					amount: convertCoinsToUAH(item.amount).toString(),
					payerData: {
						...item.payerData,
						amount: convertCoinsToUAH(item.amount),
					},
				})),
			};
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getBillsReport = createAsyncThunk(
	'billSlice/getBillsReport',
	async (billId: string, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IBillsReportResponse> = await BillService.getBillsReport(billId);
			const { data } = response;
			const payload: IBillsReportResponse = {
				...data,
				amount: convertCoinsToUAH(data.amount).toString(),
				fee: convertCoinsToUAH(data.fee).toString(),
				subBills: data.subBills.map((item) => ({
					...item,
					amount: convertCoinsToUAH(item.amount).toString(),
					fee: convertCoinsToUAH(item.fee).toString(),
				})),
				relatedBills: data.relatedBills.map((item) => ({
					...item,
					amount: convertCoinsToUAH(item.amount).toString(),
					fee: convertCoinsToUAH(item.fee).toString(),
					subBills: item.subBills.map((subBillItem) => ({
						...subBillItem,
						amount: convertCoinsToUAH(subBillItem.amount).toString(),
						fee: convertCoinsToUAH(subBillItem.fee).toString(),
					})),
				})),
			};
			return payload;
		} catch (error) {
			return rejectWithValue(getErrorResponse(error));
		}
	}
);

export const getProvidersList = createAsyncThunk('billSlice/getProvidersList', async (_, { rejectWithValue }) => {
	try {
		const response = await BillService.getProvidersList();
		return response.data;
	} catch (error) {
		return rejectWithValue(getErrorResponse(error));
	}
});
