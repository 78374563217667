import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { AxiosResponse } from 'axios';
import { useAppDispatch } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { ReceiptPreview, ReceiptProviderServiceList } from 'components/Receipt';
import { Loader } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';
import {
	convertServiceProviderDataUAHToCoins,
	getApartmentDataFromReceipt,
	getErrorMessage,
	getProviderServicePayment,
	getReceiptPaymentTotalAmount,
	sortReportProvidersByType,
} from 'utils/helpers';
import { useDevice } from 'utils/hooks';
import { ReportService } from 'utils/services';
import {
	IPaymentResponse,
	IProviderServiceResponse,
	IReceiptAnauthorizedResponse,
	ISingleReceiptPayProviderServiceRequest,
	ReportReceiptPayRequest,
} from 'utils/types';
import styles from './index.module.scss';

interface ReceiptPublicContent {
	data: IReceiptAnauthorizedResponse;
	token: string;
}

export const ReceiptPublicContent: FC<ReceiptPublicContent> = ({ data, token }) => {
	const dispatch = useAppDispatch();

	const { isMobile } = useDevice();

	const history = useHistory();

	const [totalAmount, setTotalAmount] = useState(0);
	const [selectedServiceList, setSelectedServiceList] = useState<ISingleReceiptPayProviderServiceRequest[]>([]);
	const [serviceList, setServiceList] = useState<IProviderServiceResponse[][]>([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const selectedServiceListPayload: ISingleReceiptPayProviderServiceRequest[] = data.serviceProviderData
			.map(getProviderServicePayment)
			.filter((item) => +item.incomeBalance > 0);
		const serviceListPayload: IProviderServiceResponse[][] = sortReportProvidersByType(data.serviceProviderData);
		setSelectedServiceList(selectedServiceListPayload);
		setServiceList(serviceListPayload);
	}, [data]);

	useEffect(() => {
		setTotalAmount(getReceiptPaymentTotalAmount(selectedServiceList));
	}, [selectedServiceList]);

	const handleClickPay = async (): Promise<void> => {
		try {
			setIsLoading(true);
			const returnUrl = `${process.env.REACT_APP_URL}${ROUTER_URL.RECEIPT_PAY_PUBLIC_STATUS.split('/')
				.slice(0, 3)
				.join('/')}`;
			const reqBody: ReportReceiptPayRequest = {
				returnUrl,
				serviceProviderData: convertServiceProviderDataUAHToCoins(selectedServiceList),
			};
			const response: AxiosResponse<IPaymentResponse[]> = await ReportService.payUnathorizedReceipt(reqBody, token);
			const redirectUrl = response.data[0].link;
			window.location.href = redirectUrl;
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			if (errorMessage === 'Unauthorized') {
				history.replace(ROUTER_URL.NOT_FOUND);
			} else {
				dispatch(showToast({ message: errorMessage }));
			}
		} finally {
			setIsLoading(false);
		}
	};

	const handleChangeSelectedList = (serviceList: ISingleReceiptPayProviderServiceRequest[]): void => {
		setSelectedServiceList(serviceList);
	};

	return (
		<>
			<ReceiptPreview
				className={styles.content}
				apartmentData={getApartmentDataFromReceipt(data)}
				amountToPay={totalAmount}
				onClickPay={handleClickPay}
				isDisabled={isLoading || !totalAmount}
				isDisabledMap={isMobile}
				isDisabledActions
			>
				<ReceiptProviderServiceList
					pageName={FIREBASE_EVENT_ANALYTICS_PAGE.PUBLIC.RECEIPT}
					selectedServiceList={selectedServiceList}
					onChangeSelectedList={handleChangeSelectedList}
					defaultServiceList={serviceList}
				/>
			</ReceiptPreview>
			{isLoading && <Loader />}
		</>
	);
};
