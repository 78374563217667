import { ADD_ADDRESS_FIELD_NAME, VALIDATION_ERROR } from 'utils/enums';

export const OTP_LENGTH = 4;

export const RADABANK_OTP_LENGTH = 6;

export const MIN_LENGTH = 2;

export const APARTMENT_VALIDATION_ERROR = {
	[ADD_ADDRESS_FIELD_NAME.REGION]: VALIDATION_ERROR.ADDRES_REGION_REQUIRED,
	[ADD_ADDRESS_FIELD_NAME.CITY]: VALIDATION_ERROR.ADDRES_CITY_REQUIRED,
	[ADD_ADDRESS_FIELD_NAME.STREET]: VALIDATION_ERROR.ADDRES_STREET_REQUIRED,
	[ADD_ADDRESS_FIELD_NAME.HOUSE]: VALIDATION_ERROR.ADDRES_HOUSE_REQUIRED,
	[ADD_ADDRESS_FIELD_NAME.FLAT]: VALIDATION_ERROR.ADDRES_FLAT_REQUIRED,
};
