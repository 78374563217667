import { FC, HTMLProps, MouseEvent } from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as QuestionSVG } from 'assets/icons/question.svg';
import classNames from 'classnames';
import { useAppDispatch } from 'store/hooks';
import { openModal } from 'store/system/reducer';
import { PORTMONE_SERVICE_CODE } from 'utils/constants';
import { PROVIDER_TYPE } from 'utils/enums';
import { findServiceProviderAccountIdValue } from 'utils/helpers';
import { FirebaseAnalytics } from 'utils/services';
import { IBillsSubBillItem, IProviderServiceResponse, IReceiptOtherServiceResponse } from 'utils/types';
import { ProviderDefaultInfoModal } from '../ProviderDefaultInfoModal';
import { ProviderPortmoneInfoModal } from '../ProviderPortmoneInfoModal';
import styles from './index.module.scss';

interface ProviderPreviewProps extends Omit<HTMLProps<HTMLDivElement>, 'data'> {
	description: string;
	title: string;
	icon: string;
	code: string;
	providerType: PROVIDER_TYPE;
	data?: IReceiptOtherServiceResponse | IProviderServiceResponse | IBillsSubBillItem;
}

export const ProviderPreview: FC<ProviderPreviewProps> = ({
	description,
	title,
	icon,
	className,
	code,
	providerType,
	data,
	...props
}) => {
	const dispatch = useAppDispatch();

	const handleOpenModal = (event: MouseEvent<HTMLOrSVGElement>): void => {
		event.stopPropagation();
		FirebaseAnalytics.logEvent('question', providerType);

		dispatch(
			openModal({
				content:
					providerType === PROVIDER_TYPE.PORTMONE && data ? (
						<ProviderPortmoneInfoModal
							data={data}
							title={title}
							providerAccountId={
								(data as IProviderServiceResponse)?.providerAccountId ??
								findServiceProviderAccountIdValue(
									(data as IReceiptOtherServiceResponse)?.serviceFieldsValues ?? [],
									data as IReceiptOtherServiceResponse
								)
							}
							icon={
								(data as IBillsSubBillItem).service?.imageUrl ??
								(data as IReceiptOtherServiceResponse).imageUrl ??
								(data as IProviderServiceResponse)?.categoryImage ??
								''
							}
						/>
					) : (
						<ProviderDefaultInfoModal data={data as IProviderServiceResponse | IBillsSubBillItem} />
					),
			})
		);
	};

	return (
		<div className={classNames(styles.card, className)} {...props}>
			<div className={styles.card__logos}>
				<div
					className={classNames(styles.card__icon, {
						[styles.card__icon_category]: providerType === PROVIDER_TYPE.PORTMONE,
						[styles.card__icon_gas]: code === PORTMONE_SERVICE_CODE.NAFTOGAS,
						[styles.card__icon_energo]: code === PORTMONE_SERVICE_CODE.ENERGOZBUT,
					})}
				>
					<img src={icon} alt="provider icon" />
				</div>
				{((providerType === PROVIDER_TYPE.PORTMONE && data) || providerType !== PROVIDER_TYPE.PORTMONE) && (
					<QuestionSVG className={styles.card__question} onClick={handleOpenModal} />
				)}
			</div>
			<div className={styles.card__info}>
				<div className={styles.title}>
					<Typography className={styles.title__text} variant="subtitle1" component="p" title={title}>
						{title}
					</Typography>
				</div>
				<Typography variant="body2" color="var(--color-neutral-500)">
					{description}
				</Typography>
			</div>
		</div>
	);
};
