import { FC, PropsWithChildren, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

export const AdditionalIncomeSourceContainer: FC<PropsWithChildren> = ({ children }) => {
	const { control, setValue } = useFormContext();

	const additionalIncome = useWatch({ control, name: 'amount_add' });

	const hasIncome = Number(additionalIncome) > 0;

	useEffect(() => {
		if (!hasIncome) {
			setValue('source_income', '');
			setValue('scope_enterprise', '')
		}
	}, [hasIncome]);

	return hasIncome ? children : null;
};
