import { FC, memo } from 'react';
import { InfoHintModalAnchor, InfoHintModalAnchorProps } from 'components/shared';
import { HostIdInfoModalContent } from '../HostIdInfoModalContent';

export const HostIdInfoModalAnchor: FC<InfoHintModalAnchorProps> = memo((props) => {
	return (
		<InfoHintModalAnchor {...props}>
			<HostIdInfoModalContent />
		</InfoHintModalAnchor>
	);
});
