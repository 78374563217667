import { FC, useEffect, useMemo, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { GooglePay } from '@fresha/capacitor-plugin-googlepay';
import { PaymentData, PaymentDataRequest } from '@fresha/capacitor-plugin-googlepay/dist/types';
import GooglePayButton from '@google-pay/button-react';
import { Typography } from '@mui/material';
import { ReactComponent as AddCircleSVG } from 'assets/icons/add-circle.svg';
import { AxiosError } from 'axios';
import { getCardsSelector } from 'store/cards/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { CustomButton, CustomModal } from 'components/shared';
import {
	DEFAULT_GOOGLE_PAY_CONFIG,
	DEFAULT_GOOGLE_PAY_DEFAULT_TRANSACTION_INFO,
	FIREBASE_EVENT_ANALYTICS_BUTTON,
} from 'utils/constants';
import { PAY_TOKEN_TYPE, PAYMENT_PROVIDER } from 'utils/enums';
import { getAmountTitle, getErrorMessage } from 'utils/helpers';
import { FirebaseAnalytics } from 'utils/services';
import { IDefaultModalProps, IErrorResponse, PaymentMethods, StringOrNull } from 'utils/types';
import { ApplePayButton } from './ApplePayButton';
import { PaymentMethodCardItem } from './PaymentMethodCardItem';
import styles from './index.module.scss';

interface PaymentMethodModalProps extends IDefaultModalProps {
	totalAmount: number;
	totalFeeAmount?: number;
	isShowPaymentData?: boolean;
	onPayByCard: (cardId: string, provider: StringOrNull) => Promise<void>;
	onPayByAnotherCard?: () => Promise<void>;
	onPayByWallet: (token: string, payTokenType: PAY_TOKEN_TYPE) => Promise<void>;
	onBeforeGooglePay?: () => void;
	pageName: string;
	allowedMethods: Partial<PaymentMethods>;
}

export const PaymentMethodModal: FC<PaymentMethodModalProps> = ({
	totalAmount,
	onPayByCard,
	onPayByAnotherCard,
	isShowPaymentData,
	onPayByWallet,
	totalFeeAmount = 0,
	onBeforeGooglePay,
	pageName,
	allowedMethods,
	...props
}) => {
	const dispatch = useAppDispatch();

	const cardsReducer = useAppSelector(getCardsSelector);

	const [isLoading, setIsLoading] = useState(false);

	const totalPrice = useMemo(() => (totalAmount + totalFeeAmount).toString(), [totalAmount, totalFeeAmount]);

	const googlePayConfig = useMemo(
		(): PaymentDataRequest | google.payments.api.PaymentDataRequest => ({
			...DEFAULT_GOOGLE_PAY_CONFIG,
			transactionInfo: {
				...DEFAULT_GOOGLE_PAY_DEFAULT_TRANSACTION_INFO,
				totalPrice,
			},
		}),
		[totalPrice]
	);

	useEffect(() => {
		if (Capacitor.isNativePlatform()) {
			GooglePay.initialize({ environment: 'PRODUCTION' }).catch((err) => {
				console.error(err);
			});
		}
	}, []);

	const handlePayByWallet = (data: PaymentData): void => {
		onPayByWallet(btoa(data.paymentMethodData.tokenizationData.token), PAY_TOKEN_TYPE.GOOGLE);
	};

	const handlePayByApple = async (token: string, payTokenType: PAY_TOKEN_TYPE) => {
		FirebaseAnalytics.logEvent(pageName, FIREBASE_EVENT_ANALYTICS_BUTTON.PAY_BY_APPLE);
		onPayByWallet(token, payTokenType);
	};

	const handleClickPayByGoogle = async (event: Event) => {
		try {
			onBeforeGooglePay?.();
			FirebaseAnalytics.logEvent(pageName, FIREBASE_EVENT_ANALYTICS_BUTTON.PAY_BY_GOOGLE);

			if (Capacitor.isNativePlatform()) {
				event.preventDefault();
				const isReadyToPay = await GooglePay.isReadyToPay(googlePayConfig);
				if (isReadyToPay.result) {
					const data = await GooglePay.loadPaymentData(googlePayConfig as PaymentDataRequest);
					handlePayByWallet(data);
				}
			}
		} catch (error) {
			event.preventDefault();
			const errorMessage = getErrorMessage(error);
			if (errorMessage !== 'Method not implemented.') {
				dispatch(showToast({ message: errorMessage }));
			}
		}
	};

	const handlePayByAnotherCard = async (): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(pageName, FIREBASE_EVENT_ANALYTICS_BUTTON.PAY_BY_OTHER_CARD);

			if (onPayByAnotherCard) {
				setIsLoading(true);
				await onPayByAnotherCard();
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading(false);
		}
	};

	const handlePayByCard = async (cardId: string, provider: StringOrNull): Promise<void> => {
		try {
			FirebaseAnalytics.logEvent(pageName, FIREBASE_EVENT_ANALYTICS_BUTTON.PAY_BY_CARD);
			setIsLoading(true);
			await onPayByCard(cardId, provider);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			setIsLoading(false);
		}
	};

	const handleGooglePayError = (error: Error | google.payments.api.PaymentsError) => {
		console.error(getErrorMessage(error as AxiosError<IErrorResponse>));
	};

	const isWebPlatform = Capacitor.getPlatform() === 'web';

	return (
		<CustomModal {...props} className={styles.modal} title="Оберіть спосіб оплати">
			<div className={styles.content}>
				{isShowPaymentData && (
					<div className={styles.detail__list}>
						<div className={styles.detail}>
							<Typography variant="body1">Сума</Typography>
							<Typography variant="body1" whiteSpace="nowrap">
								{getAmountTitle(totalAmount)}
							</Typography>
						</div>
						<div className={styles.detail}>
							<Typography variant="body1">Комісія</Typography>
							<Typography variant="body1" whiteSpace="nowrap">
								{getAmountTitle(totalFeeAmount)}
							</Typography>
						</div>
						<div className={styles.detail}>
							<Typography variant="subtitle1">Разом</Typography>
							<Typography variant="subtitle1" whiteSpace="nowrap">
								{getAmountTitle(totalAmount + totalFeeAmount)}
							</Typography>
						</div>
					</div>
				)}
				{allowedMethods.isOtherPay && Capacitor.getPlatform() !== 'android' && (
					<ApplePayButton onSubmit={handlePayByApple} amount={totalPrice} />
				)}
				{allowedMethods.isOtherPay && (Capacitor.getPlatform() === 'web' || Capacitor.getPlatform() === 'android') && (
					<GooglePayButton
						buttonColor="black"
						buttonRadius={40}
						onClick={handleClickPayByGoogle}
						className={styles.button_gPay}
						environment="PRODUCTION"
						buttonType="plain"
						paymentRequest={googlePayConfig as google.payments.api.PaymentDataRequest}
						onLoadPaymentData={handlePayByWallet}
						onError={handleGooglePayError}
					/>
				)}
				{/* TODO: refactor */}
				{allowedMethods.isRadaPay &&
					cardsReducer.list
						.filter((item) => item.provider === PAYMENT_PROVIDER.RADABANK)
						.map((item) => (
							<PaymentMethodCardItem
								key={item.cardId}
								item={item}
								disabled={isWebPlatform}
								handlePayByCard={handlePayByCard}
								disabledMessage="Оплата карткою HOSTCARD з метою безпеки доступна лише в мобільному додатку"
							/>
						))}
				{allowedMethods.isOtherPay &&
					cardsReducer.list
						.filter((item) => item.provider !== PAYMENT_PROVIDER.RADABANK)
						.map((item) => {

							return (
								<PaymentMethodCardItem
									key={item.cardId}
									item={item}
									handlePayByCard={handlePayByCard}
								/>
							);
						})}
				{!!allowedMethods.isOtherPay && !!onPayByAnotherCard && (
					<CustomButton
						fill="clear"
						label="Інша банківська картка"
						startIcon={<AddCircleSVG />}
						onClick={handlePayByAnotherCard}
						disabled={isLoading}
					/>
				)}
			</div>
		</CustomModal>
	);
};
