// import { Buffer } from 'buffer/';
import { AES, enc } from 'crypto-js';
import { TypeOrNull } from 'utils/types';

export const getKey = () => enc.Hex.parse(process.env.REACT_APP_USER_API_URL as string).toString();

const substitutionsAfterEncryption: Map<string, string> = new Map([
	['+', '-'],
	['/', '_'],
	['=', '~'],
]);
const substitutionsBeforeDecryption: Map<string, string> = new Map([
	['-', '+'],
	['_', '/'],
	['~', '='],
]);

export const encryptData = (data: string): string => {
	const encrypted = AES.encrypt(JSON.stringify(data), getKey()).toString();
	const result = encrypted.replace(/[+/=]/g, (match: string) => substitutionsAfterEncryption.get(match) ?? match);
	return result;
};

export const decryptData = (encryptedData: string): string => {
	const toDecrypt = encryptedData.replace(
		/[-_~]/g,
		(match: string) => substitutionsBeforeDecryption.get(match) ?? match
	);
	const result = AES.decrypt(toDecrypt, getKey()).toString(enc.Utf8);
	return result ? JSON.parse(result) : result;
};

export const decryptBase64 = (base64: string): string => {
	const words = enc.Base64.parse(base64);
	return enc.Utf8.stringify(words);
};

export const decryptObjectData = <T extends Record<string, unknown>>(encryptedObject: string): TypeOrNull<T> => {
	const decryptedObject = decryptData(encryptedObject);

	return decryptedObject ? JSON.parse(decryptedObject) : null;
};
