import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { ReactComponent as AddCircleSVG } from 'assets/icons/add-circle.svg';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import {
	addApartment,
	addSingleReceiptOtherService,
	getApartmentList,
	getApartmentsSelector,
	getBillReceiptSelector,
	getIsUserHasFullAccessSelector,
	getSingleReceipt,
	getUserProfileSelector,
	paySingleReceipt,
	resetDocumentViewer,
	resetReceipt,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { PaymentMethodModal } from 'components/PaymentMethodModal';
import {
	AddPortmoneServiceModal,
	ReceiptPortmoneDefaultServiceList,
	ReceiptPortmoneServiceList,
	ReceiptPreview,
	ReceiptProviderServiceList,
	ReceiptProvidersListPreview,
} from 'components/Receipt';
import { CustomButton, Loader } from 'components/shared';
import {
	FIREBASE_EVENT_ANALYTICS_BUTTON,
	FIREBASE_EVENT_ANALYTICS_PAGE,
	GOOGLE_PAY_MAX_AMOUNT_PORTMONE,
	GOOGLE_PAY_MAX_AMOUNT_SERVICES,
} from 'utils/constants';
import {
	ERROR_CODE,
	ERROR_MESSAGE,
	PAY_TOKEN_TYPE,
	PAYMENT_PROVIDER,
	PROVIDER_TYPE,
	ROUTER_URL,
	STORAGE_KEY,
} from 'utils/enums';
import {
	checkIsKharkivRegionApartment,
	convertCoinsToUAH,
	convertServiceProviderDataUAHToCoins,
	convertUAHToCoins,
	decryptData,
	getApartmentDataFromReceipt,
	getAppartmentErrorMessage,
	getData3DS,
	getErrorMessage,
	getErrorResponse,
	getOtherPortmoneAmountFromTariffItems,
	getOtherProviderServicePayment,
	getProviderServicePayment,
	getReceiptPaymentTotalAmount,
	getReceiptRequestBody,
	getReceiptReturnUrl,
	getReceiptRoute,
	getReceiptStatusRoute,
	getStaticTotalFeeTitle,
	getUserFullName,
	removeReceiptList,
} from 'utils/helpers';
import { useOpen } from 'utils/hooks';
import { CardService, FirebaseAnalytics, RadabankService, StorageService } from 'utils/services';
import {
	IAddOtherServiceToSingleReceipt,
	IApartmentResponse,
	IBillApartments,
	IBillReceipt,
	ICardRequestResponse,
	IPaymentResponse,
	IReceiptPayRequest,
	IReceiptResponse,
	ISingleReceiptPayProviderServiceRequest,
	IUserProfileResponse,
	PaymentMethods,
	ReceiptOtherServiceTariffItems,
	StringOrNull,
} from 'utils/types';
import styles from './index.module.scss';

type ReceiptPageParams = {
	apartmentAccountId: string;
};

export const ReceiptPage: FC = () => {
	const dispatch = useAppDispatch();

	const history = useHistory();
	const params = useParams<ReceiptPageParams>();

	const paymentModal = useOpen();
	const otherServicesModal = useOpen();

	const receipt: IBillReceipt = useAppSelector(getBillReceiptSelector);
	const profile: IUserProfileResponse | null = useAppSelector(getUserProfileSelector);
	const apartment: IBillApartments = useAppSelector(getApartmentsSelector);
	const isHasFullAccess = useAppSelector(getIsUserHasFullAccessSelector);

	const [selectedServiceList, setSelectedServiceList] = useState<ISingleReceiptPayProviderServiceRequest[]>([]);
	const [paymentSum, setPaymentSum] = useState(0);
	const [receiptPayRequest, setReceiptPayRequest] = useState<IReceiptPayRequest | null>(null);
	const [selectedServicesLength, setSelectedServiceLength] = useState({ portmone: 0, default: 0 });
	const [feeList, setFeeList] = useState<{ serviceId: string | number; amount: number }[]>([]);
	const [totalFeeAmount, setTotalFeeAmount] = useState(0);
	const [apartmentAccountId, setApartmentAccountId] = useState<null | number>(null);

	useEffect(() => {
		const apartmentAccountId = decryptData(params.apartmentAccountId);

		if (apartmentAccountId) {
			setApartmentAccountId(+apartmentAccountId);
			setStorageValue();
		} else {
			history.replace(ROUTER_URL.NOT_FOUND);
		}

		return () => {
			dispatch(resetReceipt());
		};
	}, []);

	useEffect(() => {
		if (!apartment.list.length && profile && apartmentAccountId) {
			getUserApartments(profile.userId, apartmentAccountId);
		}
		if (profile && apartmentAccountId && (receipt.data?.apartmentAccountId !== apartmentAccountId || !receipt.data)) {
			getReceipt(apartmentAccountId, profile.userId);
		}
	}, [apartmentAccountId, profile]);

	useEffect(() => {
		if (receipt.data && !selectedServiceList.length && !feeList.length) {
			const receiptServicesListRequestData: ISingleReceiptPayProviderServiceRequest[] = Object.values(
				receipt.data.serviceProviderData
			)
				.flat()
				.map(getProviderServicePayment)
				.filter((item) => +item.incomeBalance > 0);
			const receiptOtherServicesListRequestData: ISingleReceiptPayProviderServiceRequest[] =
				receipt.data.otherPaymentServices
					.map((item) =>
						getOtherProviderServicePayment(
							item,
							getOtherPortmoneAmountFromTariffItems(
								item.tarifItems as ReceiptOtherServiceTariffItems | ReceiptOtherServiceTariffItems[]
							)
								? getOtherPortmoneAmountFromTariffItems(
										item.tarifItems as ReceiptOtherServiceTariffItems | ReceiptOtherServiceTariffItems[]
								  ).toString()
								: (item.amount - item.payed).toString()
						)
					)
					.filter((item: ISingleReceiptPayProviderServiceRequest) => +item.incomeBalance > 0);
			const selectedServicesListPayload = [...receiptServicesListRequestData, ...receiptOtherServicesListRequestData];
			setSelectedServiceList(selectedServicesListPayload);
		}
	}, [receipt.data, feeList.length]);

	useEffect(() => {
		if (receipt.data) {
			dispatch(resetDocumentViewer());
			if (selectedServiceList.length) {
				setSelectedServiceList([]);
				setFeeList([]);
			}
		}
	}, [receipt.data?.id]);

	useEffect(() => {
		const selectedServicesLength = selectedServiceList
			.filter((item) => +item.incomeBalance > 0)
			.reduce(
				(acc, item) => {
					const payload = { ...acc };
					if (item.providerType === PROVIDER_TYPE.PORTMONE) {
						payload.portmone += 1;
					} else {
						payload.default += 1;
					}
					return payload;
				},
				{ portmone: 0, default: 0 }
			);
		setSelectedServiceLength(selectedServicesLength);
		setPaymentSum(getReceiptPaymentTotalAmount(selectedServiceList));
	}, [selectedServiceList]);

	useEffect(() => {
		if (profile && receipt.data) {
			handleSetRequestBody(profile, receipt.data, selectedServiceList, paymentSum, totalFeeAmount);
		}
	}, [selectedServiceList, profile, paymentSum, receipt.data, totalFeeAmount]);

	useEffect(() => {
		const payload = feeList.reduce((acc, item) => {
			const isServiceSelected = selectedServiceList.find(
				(selectedItem) => selectedItem.serviceId === item.serviceId || selectedItem.id === item.serviceId
			);
			return isServiceSelected ? acc + item.amount : acc;
		}, 0);
		setTotalFeeAmount(payload);
	}, [feeList, selectedServiceList]);

	const setStorageValue = async (): Promise<void> => {
		const data = await removeReceiptList();
		if (data) {
			setSelectedServiceList(data);
		}
	};

	const getUserApartments = async (userId: string, apartmentAccountId: number): Promise<void> => {
		try {
			const response = await dispatch(getApartmentList(userId)).unwrap();
			await addUserApartments(response.listApartment, apartmentAccountId, userId);
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const getReceipt = async (apartmentAccountId: number, userId: string): Promise<void> => {
		try {
			await dispatch(getSingleReceipt(getReceiptRequestBody(apartmentAccountId, userId))).unwrap();

			if (apartment.list.length) {
				await addUserApartments(apartment.list, apartmentAccountId, userId);
			}
		} catch (error) {
			const errorResponse = getErrorResponse(error);

			if (errorResponse?.errorData?.code === ERROR_CODE.RECEIPT_NOT_FOUND) {
				dispatch(showToast({ message: ERROR_MESSAGE.RECEIPT_NOT_FOUND }));
				history.push(ROUTER_URL.NOT_FOUND);
			} else {
				dispatch(showToast({ message: getAppartmentErrorMessage(error) }));
			}
		}
	};

	const addUserApartments = async (
		list: IApartmentResponse[],
		apartmentAccountId: number,
		userId: string
	): Promise<void> => {
		const isUserHasApartment = list.find((item) => item.apartmentAccountId === apartmentAccountId);
		if (!isUserHasApartment) {
			await dispatch(addApartment({ apartmentAccountId, userId })).unwrap();
			dispatch(showToast({ message: 'Адреса збережена в обліковому записі', color: 'success' }));
		}
	};

	const handleChangeSelectedList = (serviceList: ISingleReceiptPayProviderServiceRequest[]): void => {
		setSelectedServiceList(serviceList);
	};

	const handleClickPay = async (): Promise<void> => {
		try {
			if (!receiptPayRequest) {
				throw new Error(`${ERROR_MESSAGE.SOMETHING_WENT_WRONG}, спробуйте пізніше`);
			}
			if (convertCoinsToUAH(receiptPayRequest.amount) < 10) {
				throw new Error('Мінімальна сума платежу 10₴');
			}
			paymentModal.handleOpen();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handlePayByCard = async (cardFromId: string, provider: StringOrNull): Promise<void> => {
		if (receiptPayRequest) {
			// eslint-disable-next-line object-curly-newline
			const { apartmentAccountId, userId, amount, id, serviceProviderData, clientPhone, clientName } =
				receiptPayRequest;

			let reqBody: IReceiptPayRequest = {
				apartmentAccountId,
				amount,
				returnUrl: getReceiptReturnUrl(params.apartmentAccountId, process.env.REACT_APP_URL),
				id,
				serviceProviderData,
				cardFromId,
				userId: userId as string,
				data3DS2: getData3DS(),
				clientPhone,
				clientName,
			};

			if (provider === PAYMENT_PROVIDER.RADABANK) {
				const radabankData = await RadabankService.getInternalReqPayload({ userId });
				const deviceData = await RadabankService.getReqPayload({});

				reqBody = {
					...reqBody,
					payToken: btoa(JSON.stringify({ ...radabankData, userId: undefined, ...deviceData })),
					payTokenType: PAY_TOKEN_TYPE.RADABANK,
				};
			}

			const responseData: IPaymentResponse[] = await dispatch(paySingleReceipt(reqBody)).unwrap();
			history.push(getReceiptStatusRoute(params.apartmentAccountId, responseData[0].billId));
		}
	};

	const handlePayByWallet = async (payToken: string, payTokenType: PAY_TOKEN_TYPE): Promise<void> => {
		try {
			if (receiptPayRequest) {
				// eslint-disable-next-line object-curly-newline
				const { apartmentAccountId, userId, amount, id, serviceProviderData, clientPhone, clientName } =
					receiptPayRequest;
				const reqBody: IReceiptPayRequest = {
					apartmentAccountId,
					amount,
					id,
					serviceProviderData,
					clientPhone,
					clientName,
					payToken,
					payTokenType,
					userId: userId as string,
					data3DS2: getData3DS(),
					returnUrl: getReceiptReturnUrl(params.apartmentAccountId, process.env.REACT_APP_URL),
				};
				const responseData: IPaymentResponse[] = await dispatch(paySingleReceipt(reqBody)).unwrap();
				history.push(getReceiptStatusRoute(params.apartmentAccountId, responseData[0].billId));
			}
		} catch (err) {
			dispatch(showToast({ message: getErrorMessage(err) }));
		}
	};

	const handlePayByAnotherCard = async (): Promise<void> => {
		if (receiptPayRequest) {
			if (
				(selectedServicesLength.default && !selectedServicesLength.portmone) ||
				(!selectedServicesLength.default && selectedServicesLength.portmone === 1)
			) {
				const response: IPaymentResponse[] = await dispatch(paySingleReceipt(receiptPayRequest)).unwrap();
				const paymentRedirectUrl = response[0].link;
				if (Capacitor.isNativePlatform()) {
					await Browser.open({ url: paymentRedirectUrl });
				} else {
					window.location.href = paymentRedirectUrl;
				}
				// portmone and default services possible to pay only by added card
			} else {
				await StorageService.set(STORAGE_KEY.RECEIPT_SELECTED_LIST, selectedServiceList);
				const createCardResponse: AxiosResponse<ICardRequestResponse> = await CardService.createRequest({
					userId: profile?.userId as string,
					// get value from parameters because need hashed value for a routing
					returnUrl: `${process.env.REACT_APP_URL}${getReceiptRoute(params.apartmentAccountId)}`,
				});
				// in an app-url-litener we close the browser for ios
				if (Capacitor.isNativePlatform()) {
					await Browser.open({ url: createCardResponse.data.link });
					Browser.addListener('browserFinished', setStorageValue);
				} else {
					window.location.href = createCardResponse.data.link;
				}
			}
		}
	};

	const handleChangeFeeValue = (serviceId: string | number, feeAmount: number): void => {
		setFeeList((prevState) => {
			let payload = prevState;
			const isServiceInList = payload.find((item) => item.serviceId === serviceId);
			if (isServiceInList) {
				payload = payload.map((item) => (item.serviceId === serviceId ? { ...item, amount: feeAmount } : item));
			} else {
				payload = [...payload, { serviceId, amount: feeAmount }];
			}
			return payload;
		});
	};

	const handleSetRequestBody = (
		profile: IUserProfileResponse,
		receiptData: IReceiptResponse,
		selectedServiceList: ISingleReceiptPayProviderServiceRequest[],
		totalAmount: number,
		totalFeeAmount: number
	): void => {
		const serviceProviderData: ISingleReceiptPayProviderServiceRequest[] =
			convertServiceProviderDataUAHToCoins(selectedServiceList);
		const payload: IReceiptPayRequest = {
			apartmentAccountId: `${receiptData.apartmentAccountId}`,
			id: receiptData.id,
			month: receiptData.month,
			userId: profile.userId,
			clientName: getUserFullName(profile),
			clientPhone: profile.phone,
			amount: convertUAHToCoins(totalAmount),
			sumBalance: convertUAHToCoins(totalAmount).toString(),
			returnUrl: getReceiptStatusRoute(params.apartmentAccountId, '', process.env.REACT_APP_URL),
			fee: convertUAHToCoins(totalFeeAmount).toString(),
			serviceProviderData,
		};
		setReceiptPayRequest(payload);
	};

	const handleAddOtherProviderService = async (reqBody: IAddOtherServiceToSingleReceipt): Promise<void> => {
		try {
			if (profile) {
				FirebaseAnalytics.logEvent(
					FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SINGLE_RECEIPT,
					FIREBASE_EVENT_ANALYTICS_BUTTON.SR_ADD_SERVICE
				);
				await dispatch(addSingleReceiptOtherService(reqBody)).unwrap();
				const receiptResponse = await dispatch(
					getSingleReceipt(getReceiptRequestBody(reqBody.serviceRequestData.apartmentAccountId, profile.userId))
				).unwrap();
				const serviceResponseData = receiptResponse?.otherPaymentServices.find(
					(item) => item.id === reqBody.serviceRequestData.serviceId
				);

				if (serviceResponseData) {
					setSelectedServiceList([
						...selectedServiceList,
						getOtherProviderServicePayment(serviceResponseData, serviceResponseData.amount.toString()),
					]);
					dispatch(showToast({ message: `${serviceResponseData.title} успішно додано до ЄК`, color: 'success' }));
				}
				otherServicesModal.handleClose();
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const handleBeforeGooglePay = () => {
		if (selectedServicesLength.default && paymentSum > GOOGLE_PAY_MAX_AMOUNT_SERVICES) {
			throw new Error(`Не більше ${GOOGLE_PAY_MAX_AMOUNT_SERVICES} грн за один платіж. Кількість платежів необмежена`);
		}
		if (selectedServicesLength.portmone && paymentSum > GOOGLE_PAY_MAX_AMOUNT_PORTMONE) {
			throw new Error(`Не більше ${GOOGLE_PAY_MAX_AMOUNT_PORTMONE} грн за один платіж. Кількість платежів необмежена`);
		}
	};

	const handleClickAddService = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SINGLE_RECEIPT,
			FIREBASE_EVENT_ANALYTICS_BUTTON.SR_CLICK_ADD_SERVICE
		);
		otherServicesModal.handleOpen();
	};

	const allowedMethods = useMemo(() => {
		if (!selectedServiceList.length) return {};

		const selectedServicesPaymentMethodsKeys = Object.keys(
			selectedServiceList[0]?.paymentMethods
		) as (keyof PaymentMethods)[];

		return selectedServicesPaymentMethodsKeys.reduce((acc, curr) => {
			return {
				...acc,
				[curr]: selectedServiceList.every((item) => item?.paymentMethods?.[curr]),
			};
		}, {});
	}, [selectedServiceList]);

	const isKharkivRegionApartment = receipt.data?.region ? checkIsKharkivRegionApartment(receipt.data.region) : false;

	return (
		<div className={classNames('page', styles.wrapper)}>
			{/* {!!receipt.data && ( */}
			<ReceiptPreview
				apartmentData={
					receipt.data
						? getApartmentDataFromReceipt(receipt.data)
						: (apartment.list?.find((item) => item.apartmentAccountId === apartmentAccountId) as IApartmentResponse)
				}
				amountToPay={paymentSum + totalFeeAmount}
				onClickPay={handleClickPay}
				isDisabled={!(paymentSum + totalFeeAmount) || receipt.isLoading || receipt.data?.isArchived || !isHasFullAccess}
			>
				{!!receipt.data && (
					<>
						{isKharkivRegionApartment && (
							<ReceiptProviderServiceList
								pageName={FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SINGLE_RECEIPT}
								selectedServiceList={selectedServiceList}
								onChangeSelectedList={handleChangeSelectedList}
							/>
						)}
						{/* always show static value as 2% */}
						<ReceiptProvidersListPreview
							title={isKharkivRegionApartment ? 'Інші платежі' : 'Платежі за цією адресою'}
							chipTitle={getStaticTotalFeeTitle(2)}
						>
							<ReceiptPortmoneDefaultServiceList
								selectedServiceList={selectedServiceList}
								onChangeSelectedList={handleChangeSelectedList}
								onChangeFee={handleChangeFeeValue}
							/>
							<ReceiptPortmoneServiceList
								selectedServiceList={selectedServiceList}
								onChangeSelectedList={handleChangeSelectedList}
								onChangeFee={handleChangeFeeValue}
							/>
							<CustomButton
								fill="clear"
								label="Додати послугу"
								startIcon={<AddCircleSVG />}
								onClick={handleClickAddService}
								disabled={receipt.data?.isArchived || !isHasFullAccess}
							/>
						</ReceiptProvidersListPreview>
					</>
				)}
			</ReceiptPreview>
			{/* )} */}
			{!!receipt.data && (
				<>
					<PaymentMethodModal
						pageName={FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SINGLE_RECEIPT}
						isOpen={paymentModal.isOpen}
						onClose={paymentModal.handleClose}
						totalAmount={paymentSum}
						totalFeeAmount={totalFeeAmount}
						onPayByCard={handlePayByCard}
						isShowPaymentData
						onPayByAnotherCard={handlePayByAnotherCard}
						onPayByWallet={handlePayByWallet}
						onBeforeGooglePay={handleBeforeGooglePay}
						allowedMethods={allowedMethods}
					/>
					<AddPortmoneServiceModal
						isOpen={otherServicesModal.isOpen}
						onClose={otherServicesModal.handleClose}
						onSubmit={handleAddOtherProviderService}
					/>
				</>
			)}
			{receipt.isLoading && <Loader />}
		</div>
	);
};
