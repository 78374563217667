import dayjs from 'dayjs';
import { SURVEY_LETTER_STEP } from 'pages/RadabankCard/Auth/AuthInfoSurveyLetter/enums';
import {
	RADABANK_CARD_CREATE_PAYMENT_INITIAL_VALUES,
	RADABANK_IBAN_MFO,
	RADABANK_OPERATIONS_LIST_DAYS_COUNT,
	RADABANK_SURVEY_LETTER_INITIAL_VALUES,
	RADABANK_TRANSFER_CREATE_PAYMENT_INITIAL_VALUES,
} from 'utils/constants';
import { DATE_FORMAT, ERROR_MESSAGE, RADABANK_BOOLEAN_VALUE, ROUTER_URL, STORAGE_KEY } from 'utils/enums';
import { StorageService } from 'utils/services';
import {
	IRadabankCreatePaymentRequest,
	IRadabankInfoOptionsItem,
	IRadabankInfoSprDataResponse,
	IRadabankInfoSprOptions,
	IRadabankInternalClientPhoneResponse,
	IRadabankInternalOperation,
	IRadabankNPoshtaInternalDivisionItem,
	IRadabankTransferCreatePaymentRequest,
	RadabankRepeatedCardOperation,
	RadabankRepeatedIBANOperation,
	RadabankRepeatedOperation,
	StringOrNull,
} from 'utils/types';
import { decryptObjectData } from './crypt';

export const getRadabankPageByStatusCode = async (statusCode: string): Promise<string> => {
	switch (statusCode) {
		case '841':
			return ROUTER_URL.RADABANK_CARD_AUTH_APPROVE_CODE;

		case '842':
		case '851':
		case '-810':
			return ROUTER_URL.RADABANK_CARD_AUTH_INFO_DIYA_BEGIN;

		case '852':
		case '853':
			return ROUTER_URL.RADABANK_CARD_AUTH_DOCUMENTS_SUBMISSION;

		case '855':
		case '-815':
			return ROUTER_URL.RADABANK_CARD_AUTH_INFO_IMAGE_SET_PHOTO;

		case '871':
			const values = await StorageService.get(STORAGE_KEY.RADABANK_INFO_ANKETA_DATA);
			let step = '';
			// TODO: rework with deep equal
			if (values && JSON.stringify(values) !== JSON.stringify(RADABANK_SURVEY_LETTER_INITIAL_VALUES)) {
				step = `?step=${SURVEY_LETTER_STEP.STEP_2}`;
			}
			return `${ROUTER_URL.RADABANK_CARD_AUTH_INFO_SET_ANKETA}${step}`;

		case '874':
			return ROUTER_URL.RADABANK_CARD_AUTH_INFO_SIGN_DOCUMENTS;

		case '876':
		case '-816':
			return ROUTER_URL.RADABANK_CARD_AUTH_INFO_IMAGE_SET_SIGN;

		case '880':
			return ROUTER_URL.RADABANK_CARD_AUTH_SIGN_DOCUMENTS_SUBMISSION;

		default:
			return ROUTER_URL.RADABANK_CARD_AUTH_STATUS;
	}
};

export const getRadabankPageToRedirect = async (data: IRadabankInternalClientPhoneResponse): Promise<string> => {
	try {
		let redirectUrl = window.location.pathname;

		if (data.infocode && data.infotoken) {
			await StorageService.set(STORAGE_KEY.RADABANK_AUTH_INFO, {
				infocode: data.infocode,
				infotoken: data.infotoken,
			});
		}

		// Infocount – кількість заявок за даним номером телефону (1-заявка існує)
		// Infoid - ІД заявки клиента по ВІ
		// Infostatusid – статус заявки клиента на ВІ
		// Якщо Infocount = 0, заявку треба створювати.
		// Якщо Infocount = 1, то тоді треба виконувати функції відповідно до змінної Infostatusid.
		if (!data.username && !data.clientcode && data.infocount === RADABANK_BOOLEAN_VALUE.FALSE) {
			redirectUrl = ROUTER_URL.RADABANK_CARD_CREATE_CARD_BANNER;
		} else if (
			!!data.username &&
			!!data.clientcode &&
			data.infocount === RADABANK_BOOLEAN_VALUE.FALSE &&
			data.cardexists === RADABANK_BOOLEAN_VALUE.FALSE
		) {
			const bankCreateCoBrandCardRequestId = await StorageService.get<string>(
				STORAGE_KEY.RADABANK_CREATE_CO_BRAND_CARD_REQUEST_ID
			);
			if (bankCreateCoBrandCardRequestId) {
				return ROUTER_URL.RADABANK_CARD_INTERNAL_CREATE_CARD_SUBMITION;
			}
			redirectUrl = ROUTER_URL.RADABANK_CARD_INTERNAL_CREATE_CARD;
		} else if (data.infostatusid && data.infocount === RADABANK_BOOLEAN_VALUE.TRUE) {
			const radabankInfoToken = await StorageService.get(STORAGE_KEY.RADABANK_AUTH_INFO);

			if (!radabankInfoToken) {
				return ROUTER_URL.RADABANK_CARD_OTHER_DEVICE;
			}

			const payload = await getRadabankPageByStatusCode(data.infostatusid);

			if (payload) {
				redirectUrl = payload;
			}
		}

		return redirectUrl;
	} catch (error) {
		if (error === ERROR_MESSAGE.RADABANK_SHOULD_LOGIN_IN) {
			return ROUTER_URL.RADABANK_CARD_AUTH_INTERNAL_AUTHORIZE;
		}
		return ROUTER_URL.ERROR;
	}
};

export const getRadabankDocumentUrl = (fileName: string): string =>
	`${process.env.REACT_APP_RADABANK_DOCUMENT_URL}/print/${fileName}`;

export const getRadabankNumberView = (cardNumber: string): string => cardNumber.replace(/(.{4})/g, '$1 ').trim();

export const checkIsOperationsListHasMoreItems = (list: IRadabankInternalOperation[][], total: number): boolean =>
	list.reduce((acc, item) => acc + item.length, 0) < total;

export const getRadabankOperationsListDateStart = (dateEnd: string): string =>
	dayjs(dateEnd, DATE_FORMAT.RADABANK_REQUEST)
		.add(-RADABANK_OPERATIONS_LIST_DAYS_COUNT, 'day')
		.format(DATE_FORMAT.RADABANK_REQUEST);

export const checkIsRadabankIban = (value: string): boolean => value.slice(4, 10) === RADABANK_IBAN_MFO;

export const sortNPoshtaDivisionsByNumber = (
	list: IRadabankNPoshtaInternalDivisionItem[]
): IRadabankNPoshtaInternalDivisionItem[] => {
	const getDivisionNumber = (label: string) => {
		const numberIndexStart = label.indexOf('№');
		if (numberIndexStart === -1) return label;

		return label
			.slice(numberIndexStart + 1)
			.trim()
			.split(/[:\s]/)[0];
	};

	return list.sort((a, b) => {
		const numbersOfDivisions = [getDivisionNumber(a.description), getDivisionNumber(b.description)];
		if (+numbersOfDivisions[0] < +numbersOfDivisions[1]) return -1;
		if (+numbersOfDivisions[0] > +numbersOfDivisions[1]) return 1;
		return 0;
	});
};

export const getMaskedRadabankPhone = (value: string) => `${value}`;

export const prepareCompareLocaleString = (value: string) => value.replace(/\s/g, '').toLocaleUpperCase();

export const transformRadabankOptionsToSelectOptions = (options: IRadabankInfoOptionsItem[]) =>
	options.map(({ id, name }) => ({ label: name, value: id }));

export const getInfoSprSelectOptions = (data: IRadabankInfoSprDataResponse): IRadabankInfoSprOptions => {
	return {
		socialStatus: transformRadabankOptionsToSelectOptions(data.social_status),
		martialStatus: transformRadabankOptionsToSelectOptions(data.marital_status),
		education: transformRadabankOptionsToSelectOptions(data.edication),
		termWork: transformRadabankOptionsToSelectOptions(data.term_work),
		contactPerson: transformRadabankOptionsToSelectOptions(data.contact_person),
		sourceIncome: transformRadabankOptionsToSelectOptions(data.source_income),
		scopeEnterprise: transformRadabankOptionsToSelectOptions(data.scope_enterprise),
	};
};

export const getRepeatedTransactionDetails = (
	isIban: boolean,
	transaction: Pick<IRadabankInternalOperation, 'merchantkey' | 'amount' | 'destination'>
): RadabankRepeatedOperation => {
	const { merchantkey, amount, destination } = transaction;

	const transactionAmount = `${Math.abs(amount)}`;

	return isIban
		? {
				ibanrec: merchantkey,
				amount: transactionAmount,
				destination,
		  }
		: {
				card: merchantkey,
				amount: transactionAmount,
				comment: destination,
		  };
};

export const getCardOperationInitialValues = (transaction: StringOrNull): IRadabankCreatePaymentRequest => {
	if (!transaction) return RADABANK_CARD_CREATE_PAYMENT_INITIAL_VALUES;

	const parsedTransaction = decryptObjectData<RadabankRepeatedCardOperation>(transaction);

	return { ...RADABANK_CARD_CREATE_PAYMENT_INITIAL_VALUES, ...parsedTransaction };
};

export const getIbanOperationInitialValues = (transaction: StringOrNull): IRadabankTransferCreatePaymentRequest => {
	if (!transaction) {
		return RADABANK_TRANSFER_CREATE_PAYMENT_INITIAL_VALUES;
	}

	const parsedTransaction = decryptObjectData<RadabankRepeatedIBANOperation>(transaction);

	return {
		...RADABANK_TRANSFER_CREATE_PAYMENT_INITIAL_VALUES,
		...parsedTransaction,
	};
};

export const getTransactionParamFromSearch = (search: string): StringOrNull => {
	const params = new URLSearchParams(search);

	return params.get('transaction');
};
