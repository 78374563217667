import { AxiosResponse } from 'axios';
import { USER_API } from 'core/api';
import { USER_API_URL } from 'utils/enums';
import {
	ApartmentCityItem,
	ApartmentFlatItem,
	ApartmentHouseItem,
	ApartmentRegionItem,
	ApartmentStreetItem,
	BaseGetApartmentAddressDto,
	GetCitiesRequest,
	GetFlatsRequest,
	GetHousesRequest,
	GetStreetsRequest,
} from 'utils/types';

class StreetsService {
	static searchRegions = async (reqBody: BaseGetApartmentAddressDto): Promise<AxiosResponse<ApartmentRegionItem[]>> =>
		USER_API.post(USER_API_URL.REGION_SEARCH, {
			...reqBody,
		});

	static searchCities = async (reqBody: GetCitiesRequest): Promise<AxiosResponse<ApartmentCityItem[]>> =>
		USER_API.post(USER_API_URL.CITY_SEARCH, {
			...reqBody,
		});

	static searchStreets = async (reqBody: GetStreetsRequest): Promise<AxiosResponse<ApartmentStreetItem[]>> =>
		USER_API.post(USER_API_URL.STREET_SEARCH, {
			...reqBody,
		});

	static searchHouses = async (reqBody: GetHousesRequest): Promise<AxiosResponse<ApartmentHouseItem[]>> =>
		USER_API.post(USER_API_URL.HOUSE_SEARCH, {
			...reqBody,
		});

	static searchFlats = async (reqBody: GetFlatsRequest): Promise<AxiosResponse<ApartmentFlatItem[]>> =>
		USER_API.post(USER_API_URL.FLAT_SEARCH, reqBody);
}

export { StreetsService };
