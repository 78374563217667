export enum ERROR_CODE {
	SESSION_NOT_AUTHORIZED = 'UAPI003',
	SESSION_NOT_FOUND = 'UAPI005',
	SHOULD_LOG_IN = 'UAPI017',
	INVALID_CONFIRM_CODE = 'U023',
	INVALID_CONFIRM_RESTORE_CODE = 'U020',
	INVALID_VERIFY_DEVICE_CODE = 'U026',
	DEVICE_CODE_IS_INCORRECT = 'U026',
	INVALID_PASSWORD_OR_NOT_FOUND = 'U003',
	INVALID_CURRENT_PASSWORD = 'U008',
	RECEIPT_NOT_FOUND = 'B005',
	SERVICE_FIELDS_NOT_FOUND = 'B005',
	SERVICE_ACCOUNT_NOT_FOUND = 'B005',
	APARTMENT_DUPLICATE_OR_NOT_FOUND = 'B007',
	RESEND_RESET_PASSWORD_TIMEOUT = 'U019',
	SERVICE_PAID_ALREADY = 'B004',
}

export enum ERROR_MESSAGE {
	INVALID_PASSWORD_OR_NOT_FOUND = 'Користувача з таким номером телефону не існує або PIN код неправильний',
	INVALID_PHONE = 'Користувача з таким номером телефону не існує',
	MIN_LENGTH = 'Мінімальна довжина 2 символи',
	APARTMENT_DUPLICATE = 'Дана адреса вже додана в акаунт',
	APARTMENT_NOT_FOUND = 'Адресу не знайдено',
	ABORT_MESSAGE = 'All requests cancelled',
	SERVICE_FIELDS_NOT_FOUND = 'Дані по сервісу не знайдено',
	APARTMENTS_MAX_COUNT = 'Додано максимальну кількість адрес',
	INVALID_CURRENT_PASSWORD = 'Введений поточний пароль невірний',
	RECEIPT_NOT_FOUND = 'Дані від комунальних підприємств не надходили. Спробуйте, будь ласка, пізніше',
	UPLOAD_AVATAR = 'Не вдалось завантажити зображення. Спробуйте, будь ласка, пізніше',
	// eslint-disable-next-line max-len
	SERVICE_BILL_REQUEST_PAID_ALREADY = 'У вас немає несплачених рахунків. Очікуйте на виставлення рахунків від вашого постачальника послуг',
	SERVICE_ACCOUNT_NOT_FOUND = 'Помилка при створенні платежу. Перевірте правильність введення реквізитів',
	SERVICE_CATEGORY_EMPTY = 'Послуга в розробці. Слідкуйте за нашими оновленнями!',

	SOMETHING_WENT_WRONG = 'Щось пішло не так',

	PUSH_NOTIFICATIONS_WENT_WRONG = 'Помилка підписки сповіщень',

	CAMERA_ACCESS_ERROR = 'Помилка доступу до камери',

	NOT_FOUND = 'Не знайдено',

	PASSWORDS_DO_NOT_MATCH = 'Паролі не співпадають',

	RADABANK_SHOULD_LOGIN_IN = 'RADABANK_SHOULD_LOGIN_IN',
	RADABANK_ACCESS_DENIED = 'access denied',
	RADABANK_INVALID_TOKEN = "Клієнт невизначений або код доступу прострочений",
}

export enum VALIDATION_ERROR {
	REQUIRED = "Обов'язкове поле",
	MIN_LENGTH = 'Мінімальна довжина 2 символи',
	SPECIAL_CHARACTERS = "Поле може містити лише такі символи ` - ` ` ' `",
	FIRST_CHARACTER = 'Поле повинно починатися з літери',
	EMAIL_INVALID = 'Неправильний формат',
	ADDRES_REGION_REQUIRED = "Назва області обов'язкове поле",
	ADDRES_CITY_REQUIRED = "Назва міста обов'язкове поле",
	ADDRES_STREET_REQUIRED = "Назва вулиці обов'язкове поле",
	ADDRES_HOUSE_REQUIRED = "Будинок обов'язкове поле",
	ADDRES_FLAT_REQUIRED = "Номер квартири обовя'зкове поле",
	PROVIDER_ACCOUNT_ID_REQUIRED = "Особовий рахунок обов'язкове поле",
	PROVIDER_ID_REQUIRED = "Постачальник послуг обов'язкове поле",
	LATIN_LETTERS_AND_NUMBERS = "Поле може містити лише літери латинського алфавіту, числа та символ ` - `",
	AMOUNT_OUT_LESS_THAN_AMOUNT_IN = "Витрати не повинні перевищувати доходи",
}

export enum RADABANK_VALIDATION_ERROR {
	IBAN = 'Довжина має бути 29 смволів',
}
