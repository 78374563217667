import { capitalize } from '@mui/material';
import { APARTMENT_ADDRESS_TOUCHED, KHARKIV_REGION_NAME, NULL_FLAT_PLACEHOLDER } from 'utils/constants';
import { ADD_ADDRESS_FIELD_NAME, ADD_APARTMENT_VARIANT, STREET_TYPE } from 'utils/enums';
import {
	ApartmentAddressItemResponse,
	ApartmentFlatItem,
	ApartmentItem,
	GetApartmentAccountIdsListRequest,
	GetApartmentAccountIdsListResponse,
	IAddApartmentRequest,
	IAddAppartmentAddressGeneric,
	IReceiptAnauthorizedResponse,
	IReceiptResponse,
	ReceiptApartment,
	TypeOrNull,
} from 'utils/types';
import { decryptBase64 } from './crypt';

export const getApartmentTitle = (apartment: Partial<ReceiptApartment>): string => {
	const streetType = `${
		apartment.streetType?.slice(0, apartment.streetType === STREET_TYPE.STREET ? 3 : 5)?.toLowerCase() ?? ''
	}`;

	const city = apartment.city
		?.split('.')
		.map((item, index) => (!index ? item.toLowerCase() : capitalize(item.trim().toLowerCase())))
		.join('. ');

	const street: string =
		apartment.street
			?.split(' ')
			?.map((item: string) => `${item?.slice(0, 1)?.toUpperCase() ?? ''}${item?.slice(1)?.toLowerCase() ?? ''}`)
			?.join(' ') ?? '';
	return `${city}, ${streetType ?? `${streetType}.`} ${street ?? ''} ${apartment?.house ?? ''}${
		apartment.flat ? `, кв. ${apartment.flat}` : ''
	}`;
};

export const getApartmentTitleFromHash = (apartmentHashedString: string): string => {
	const decryptedApartmentString = decryptBase64(apartmentHashedString);
	const [streetType, street, house, flat] = decryptedApartmentString.split('_');
	const apartment = {
		streetType,
		street,
		house,
		flat,
	};
	return getApartmentTitle(apartment);
};

export const getStreetTitle = (street: string): string =>
	street.charAt(0).toUpperCase() + street.slice(1).toLowerCase();

export const getApartmentDataFromReceipt = (
	receipt: IReceiptResponse | IReceiptAnauthorizedResponse
): ReceiptApartment => {
	const {
 apartmentAccountId, flat, house, street, streetType, lat, lon, city
} = receipt;
	return {
		apartmentAccountId,
		flat,
		house,
		street,
		streetType,
		lat,
		lon,
		city,
	};
};

export const getApartmentInitialValues = (
	userId: string,
	variant: ADD_APARTMENT_VARIANT | null
): IAddApartmentRequest => {
	let payload: IAddApartmentRequest = {
		userId,
	};

	switch (variant) {
		case ADD_APARTMENT_VARIANT.ADDRESS:
			payload = { ...payload, region: null, city: null, street: null, house: null, flat: null };
			break;

		case ADD_APARTMENT_VARIANT.PROVIDER_ACCOUNT_ID:
			payload = { ...payload, providerAccountId: '', providerId: null };
			break;

		case ADD_APARTMENT_VARIANT.APARTMENT_ACCOUNT_ID:
			payload = { ...payload, apartmentAccountId: undefined };
			break;

		default:
			break;
	}

	return payload;
};

export const flatApartmentAccountIds = (response: GetApartmentAccountIdsListResponse): number[] => [
	...new Set(
		Object.values(response).reduce((acc: number[], curr) => {
			if ('apartmentAccountIds' in curr) {
				return [...acc, ...curr.apartmentAccountIds];
			}
			if (Array.isArray(curr)) {
				return [...acc, ...curr];
			}

			return acc;
		}, [] as number[])
	),
];

const getDtoByAddress = (data: IAddApartmentRequest): GetApartmentAccountIdsListRequest | null => {
	if (!data.house) return null;

	return {
		byAddress: data.flat
			? removeApartmentFlatPlaceholderTiltle(data.flat)
			: {
					...data.house,
					portmoneData: data.house.portmoneData
						? {
								...data.house.portmoneData,
								flat: null,
						  }
						: undefined,
					hostData: data.house?.hostData
						? {
								...data.house?.hostData,
								flat: null,
						  }
						: undefined,
			  },
	};
};

const getDtoByProviderAccount = (data: IAddApartmentRequest): GetApartmentAccountIdsListRequest | null => {
	if (!data.providerId || !data.providerAccountId) return null;

	return {
		byProviderAccount: {
			provider: data.providerId,
			customerNumber: data.providerAccountId,
		},
	};
};

const getDtoByHostId = (data: IAddApartmentRequest): GetApartmentAccountIdsListRequest | null => {
	if (!data.apartmentAccountId) return null;

	return {
		byHostId: {
			hostId: data.apartmentAccountId,
		},
	};
};

export const getAddAppartmentDto = (
	apartmentVariant: ADD_APARTMENT_VARIANT,
	data: IAddApartmentRequest
): TypeOrNull<GetApartmentAccountIdsListRequest> => {
	switch (apartmentVariant) {
		case ADD_APARTMENT_VARIANT.ADDRESS:
			return getDtoByAddress(data);
		case ADD_APARTMENT_VARIANT.PROVIDER_ACCOUNT_ID:
			return getDtoByProviderAccount(data);
		default:
			return getDtoByHostId(data);
	}
};

export const sortApartmentArrayStringResponse = (a: string, b: string): number => {
	// Extract numerical part from the strings
	const regex = /(\d+)/g;
	const aNum = a.match(regex) !== null ? +(a.match(regex) as RegExpMatchArray)[0] : 0;
	const bNum = b.match(regex) !== null ? +(b.match(regex) as RegExpMatchArray)[0] : 0;

	// Compare numerical parts
	if (aNum !== bNum) {
		return aNum - bNum;
	}

	// Extract alphabetic part from the strings
	const aAlpha = a.replace(regex, '').toUpperCase();
	const bAlpha = b.replace(regex, '').toUpperCase();

	// Compare alphabetic parts
	if (aAlpha !== bAlpha) {
		return aAlpha.localeCompare(bAlpha);
	}

	// Compare special characters
	const aSpecial = a.replace(/[0-9A-Z\s]+/g, '');
	const bSpecial = b.replace(/[0-9A-Z\s]+/g, '');
	if (aSpecial !== bSpecial) {
		return aSpecial.localeCompare(bSpecial);
	}

	// If all parts are equal, maintain original order
	return 0;
};

export const sortApartmentItemsResponse = (a: ApartmentItem, b: ApartmentItem) =>
	sortApartmentArrayStringResponse(a.title ?? '', b.title ?? '');

export const setApartmentFlatPlaceholderTitle = (flat: ApartmentFlatItem): ApartmentFlatItem =>
	flat.title ? flat : { ...flat, title: NULL_FLAT_PLACEHOLDER };

export const removeApartmentFlatPlaceholderTiltle = (flat: ApartmentFlatItem): ApartmentFlatItem =>
	flat.title === NULL_FLAT_PLACEHOLDER ? { ...flat, title: null } : flat;

export const renameApartmentFlatsResponse = (flats: ApartmentFlatItem[]): ApartmentFlatItem[] =>
	flats.map(setApartmentFlatPlaceholderTitle);

export const remapApartmentFlatsResponse = (flats: ApartmentFlatItem[]): ApartmentFlatItem[] =>
	flats.length > 1
		? renameApartmentFlatsResponse(flats).sort(sortApartmentItemsResponse)
		: flats.filter((flat) => flat.title);

export const getAddAppartmentByAddressTouchedFields = (
	touched: IAddAppartmentAddressGeneric<boolean>,
	fieldName: string
): IAddAppartmentAddressGeneric<boolean> => {
	let touchedPayload = { ...touched };
	switch (fieldName) {
		case ADD_ADDRESS_FIELD_NAME.REGION:
			touchedPayload = { ...APARTMENT_ADDRESS_TOUCHED, region: true };
			break;
		case ADD_ADDRESS_FIELD_NAME.CITY:
			touchedPayload = { ...APARTMENT_ADDRESS_TOUCHED, region: true, city: true };
			break;
		case ADD_ADDRESS_FIELD_NAME.STREET:
			touchedPayload = { ...APARTMENT_ADDRESS_TOUCHED, region: true, city: true, street: true };
			break;

		case ADD_ADDRESS_FIELD_NAME.HOUSE:
			touchedPayload = { ...touchedPayload, house: true, flat: false };
			break;

		default:
			touchedPayload = { ...touchedPayload, [fieldName]: true };
			break;
	}
	return touchedPayload;
};

export const checkIsKharkivRegionApartment = (region: string): boolean =>
	region.trim().toLowerCase() === KHARKIV_REGION_NAME.toLowerCase();



export const getIsDisabledApartmentAddressChoice =
(options: ApartmentAddressItemResponse<unknown, unknown>[], portmoneLimit = 0) => portmoneLimit
? options.filter((value) => value.portmoneData).length >= portmoneLimit
: false;
