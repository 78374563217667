import { useEffect, useState } from 'react';

export const useDebounce = <T>(value: T, delay = 500): T => {
	const [debouncedValue, setDebouncedValue] = useState<T>(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return debouncedValue;
};

export const useDebounceState = <T,>(initialValue: T) => {
	const [value, setValue] = useState<T>(initialValue);

	const debouncedValue = useDebounce<T>(value);

	return { value, setValue, debouncedValue };
};
