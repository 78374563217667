import { FC, KeyboardEvent, useState } from 'react';
import { NumberFormatValues, PatternFormat } from 'react-number-format';
import { useLocation } from 'react-router';
import { TextField, Typography } from '@mui/material';
import {
	APARTMENT_ACCOUNT_ID_CHARS_LENGTH,
	FIREBASE_EVENT_ANALYTICS_BUTTON,
	FIREBASE_EVENT_ANALYTICS_PAGE,
} from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';
import { IAddApartmentRequest } from 'utils/types';
import { apartmentAccountIdValidationSchema } from 'utils/validation';
import { HostIdInfoModalAnchor } from '../HostIdInfoModalAnchor';
import styles from './index.module.scss';

interface AddApartmentByIdProps {
	data: IAddApartmentRequest;
	onChange: (data: IAddApartmentRequest) => void;
	setIsValid: (value: boolean) => void;
	onSubmit: (e: KeyboardEvent) => void;
}

export const AddApartmentById: FC<AddApartmentByIdProps> = ({ data, onChange, setIsValid, onSubmit }) => {
	const { pathname } = useLocation();

	const [touched, setTouched] = useState(false);
	const [validationError, setValidationError] = useState('');

	const validateData = async (value: Partial<IAddApartmentRequest>): Promise<void> => {
		try {
			await apartmentAccountIdValidationSchema.validate(value.apartmentAccountId, { abortEarly: false });
			setValidationError('');
			setIsValid(true);
		} catch (error) {
			setValidationError(error.message);
			setIsValid(false);
		}
	};

	const handleChangeAppartmentData = ({ value }: NumberFormatValues): void => {
		const payload = { ...data, apartmentAccountId: value ? +value : undefined };
		onChange(payload);
		validateData(payload);
	};

	const handleBlur = (): void => {
		setTouched(true);
		validateData(data);
	};

	return (
		<>
			<div className={styles.header}>
				<Typography variant="h5">
					Вкажіть HOST ID код
					<div className={styles.icon}>
						<HostIdInfoModalAnchor
							analyticsPage={FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.ADD_ADDRESS}
							analyticsButton={FIREBASE_EVENT_ANALYTICS_BUTTON.HOST_ID}
							className={styles.icon}
						/>
					</div>
				</Typography>
			</div>
			<PatternFormat
				onKeyDown={onSubmit}
				autoFocus
				format={Array(APARTMENT_ACCOUNT_ID_CHARS_LENGTH).fill('#').join('')}
				customInput={TextField}
				value={data.apartmentAccountId?.toString() || ''}
				defaultValue=""
				onValueChange={handleChangeAppartmentData}
				onBlur={handleBlur}
				placeholder={pathname === ROUTER_URL.ADD_ADDRESS ? 'HOST ID' : '0000000000'}
				inputProps={{ inputMode: 'numeric', autoComplete: 'off' }}
				helperText={touched && validationError}
				error={touched && !!validationError}
				isAllowed={(values) => values.formattedValue === '' || +values.formattedValue.slice(0, 1) !== 0}
			/>
		</>
	);
};
