import { FC, MouseEvent, PropsWithChildren } from 'react';
import { MenuItem, Typography } from '@mui/material';
import { ReactComponent as QuestionSVG } from 'assets/icons/question.svg';
import { InfoHintModal } from 'components/shared';
import { ADD_APARTMENT_VARIANT } from 'utils/enums';
import { useOpen } from 'utils/hooks';
import { FirebaseAnalytics } from 'utils/services';
import { IAppartmentVariantItem } from 'utils/types/appartment-variant.interface';
import styles from './index.module.scss';

interface AppartmentVariantItemProps {
	item: Omit<IAppartmentVariantItem, 'children'>;
	onClickItem: (value: ADD_APARTMENT_VARIANT) => void;
	pageName: string;
	disabled?: boolean;
}

export const AppartmentVariantItem: FC<PropsWithChildren<AppartmentVariantItemProps>> = ({
	item,
	onClickItem,
	disabled = false,
	pageName,
	children,
}) => {
	const { isOpen, handleClose, handleOpen } = useOpen();

	const handleClick = () => {
		onClickItem(item.value);
	};

	const handleOpenModal = (e: MouseEvent) => {
		if(item.detailsAnalyticsKey) {
			FirebaseAnalytics.logEvent(pageName, item.detailsAnalyticsKey)
		}
		e.stopPropagation();
		handleOpen();
	};

	const isWithModal = !!children;

	return (
		<>
			<MenuItem key={item.value} className={styles.item} onClick={handleClick} disabled={disabled}>
				<div className={styles.item__icon}>
					<img src={item.icon} alt={item.title} />
				</div>

				<Typography className={styles.item__title} variant="subtitle1">
					{item.title}
					{isWithModal && (
						<div className={styles.question}>
							<QuestionSVG className={styles.question} onClick={handleOpenModal} />
						</div>
					)}
				</Typography>
			</MenuItem>
			{isWithModal && (
				<InfoHintModal isOpen={isOpen} onClose={handleClose}>
					{children}
				</InfoHintModal>
			)}
		</>
	);
};
