import { FC, TouchEvent as ReactTouchEvent, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { IonSpinner } from '@ionic/react';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { IconButton, MenuList, Typography } from '@mui/material';
import classNames from 'classnames';
import { getRadabankCurrentCardSelector, useAppSelector } from 'store';
import { Observer } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ROUTER_URL } from 'utils/enums';
import { getRadabankMobileTopUpUrl } from 'utils/helpers';
import { FirebaseAnalytics } from 'utils/services';
import { IRadabankInternalOperation } from 'utils/types';
import { RadabankOperationsDayItem } from '../OperationsDayItem';
import styles from './index.module.scss';

interface RadabankOperationsListProps {
	onObserve: (cardId: string) => Promise<void>;
	cardOperations: Record<string, IRadabankInternalOperation[]>;
	isLoading: boolean;
	canLoad: boolean;
}

export const RadabankOperationsList: FC<RadabankOperationsListProps> = ({
	onObserve,
	cardOperations,
	isLoading,
	canLoad,
}) => {
	const headerRef = useRef<HTMLDivElement>(null);
	const wrapperRef = useRef<HTMLDivElement>(null);

	const history = useHistory();

	const cardData = useAppSelector(getRadabankCurrentCardSelector);

	const [wrapperBoundingTop, setWrapperBoundingTop] = useState(0);
	const [isOpenList, setIsOpenList] = useState(false);

	useEffect(() => {
		if (wrapperRef.current) {
			// const safeAreaTop = getComputedStyle(document.documentElement)
			// 	.getPropertyValue('--safe-area-top')
			// 	?.replace('px', '');
			// const safeAreaBottom = getComputedStyle(document.documentElement)
			// 	.getPropertyValue('--safe-area-bottom')
			// 	?.replace('px', '');
			// let diffInHeight = 0;
			// if (safeAreaTop) {
			// 	diffInHeight += +safeAreaTop;
			// }
			// if (safeAreaBottom) {
			// 	diffInHeight += +safeAreaBottom;
			// }
			// size of padding top in a layout
			setWrapperBoundingTop(wrapperRef.current.getBoundingClientRect().top - 12);
		}
	}, [wrapperRef.current]);

	useEffect(() => {
		let handleTouchStart: (e: TouchEvent) => void;
		if (headerRef.current && wrapperRef.current && wrapperBoundingTop) {
			handleTouchStart = (e: TouchEvent) => {
				e.stopPropagation();
				const touchStart = e.touches[0].clientY;

				let touchDistance = 0;
				const wrapperTranslate = (wrapperRef.current as HTMLDivElement).style.translate;

				const touchMove = (e: TouchEvent) => {
					e.stopPropagation();
					touchDistance = e.touches[0].clientY - touchStart;

					if (
						touchDistance > 0 &&
						(wrapperRef.current as HTMLDivElement).getBoundingClientRect().top > wrapperBoundingTop
					) {
						return;
					}

					if (touchDistance <= -wrapperBoundingTop) {
						touchDistance = -wrapperBoundingTop;
					} else if (wrapperTranslate) {
						touchDistance = touchDistance > 0 ? -wrapperBoundingTop + touchDistance : -wrapperBoundingTop;
					}

					(wrapperRef.current as HTMLDivElement).style.translate = touchDistance
						? `0 ${touchDistance.toFixed()}px`
						: '';
				};

				const touchEnd = (e: TouchEvent) => {
					e.stopPropagation();
					headerRef.current?.removeEventListener('touchmove', touchMove);
					headerRef.current?.removeEventListener('touchend', touchEnd);

					let transitionValue = 0;

					// 120 is a value to should change style of an wrapper
					if (wrapperTranslate) {
						transitionValue = touchDistance + wrapperBoundingTop >= 120 ? 0 : -wrapperBoundingTop;
					} else if (touchDistance <= 0) {
						transitionValue = touchDistance <= -120 ? -wrapperBoundingTop : 0;
					}

					if (wrapperRef.current) {
						wrapperRef.current.style.transition = 'translate 0.2s ease-in';
						wrapperRef.current.style.translate = transitionValue ? `0 ${transitionValue}px` : '';
						setIsOpenList(!!transitionValue);
						setTimeout(() => {
							if (wrapperRef.current) {
								wrapperRef.current.style.transition = '';
							}
						}, 200);
					}
				};

				if (headerRef.current) {
					headerRef.current.addEventListener('touchmove', touchMove);
					headerRef.current.addEventListener('touchend', touchEnd);
				}
			};

			headerRef.current.addEventListener('touchstart', handleTouchStart);
		}

		return () => {
			if (headerRef.current && wrapperRef.current) {
				headerRef.current.removeEventListener('touchstart', handleTouchStart);
			}
		};
	}, [headerRef.current, wrapperRef.current, wrapperBoundingTop]);

	const handleTopUpMobile = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CARD_MAIN,
			FIREBASE_EVENT_ANALYTICS_BUTTON.TOP_UP_MOBILE_FIRST
		);
		history.push(getRadabankMobileTopUpUrl(), { from: ROUTER_URL.RADABANK_CARD });
	};

	const handleOpenCardOperationsStatement = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.CARD_MAIN,
			FIREBASE_EVENT_ANALYTICS_BUTTON.SHOW_STATEMENT
		);
		history.push(ROUTER_URL.RADABANK_CARD_OPERATIONS_STATEMENT);
	};

	const handleStopPropagation = (e: ReactTouchEvent<HTMLButtonElement>) => {
		e.stopPropagation();
	};

	const isListEmpty = !Object.values(cardOperations).flat().length;

	return (
		<div
			ref={wrapperRef}
			className={classNames('layout', styles.wrapper, {
				[styles.shadow_disabled]: isOpenList,
			})}
		>
			<div ref={headerRef} className={styles.header}>
				<IconButton
					className={styles.receipt_icon}
					onTouchStart={handleStopPropagation}
					onTouchEnd={handleStopPropagation}
					onClick={handleOpenCardOperationsStatement}
				>
					<ReceiptIcon />
				</IconButton>
			</div>
			<MenuList className={styles.list}>
				{isListEmpty && !isLoading && !canLoad && (
					<Typography variant="caption" textAlign="center" color="var(--color-neutral-500)">
						Платіть карткою, і ми покажемо ваші операції. Почніть з простого -{' '}
						<span onClick={handleTopUpMobile} className={styles.link}>
							поповніть мобільний
						</span>
					</Typography>
				)}

				{!isListEmpty &&
					Object.entries(cardOperations).map(([date, operations]) => (
						<RadabankOperationsDayItem key={date} date={date} operations={operations} />
					))}
				{isLoading && (
					<div className={styles.loader}>
						<IonSpinner className={styles.loader__dots} name="dots" />
					</div>
				)}
				{!!cardData && canLoad && !isLoading && (
					<Observer className={styles.list__observer} onObserve={() => onObserve(cardData.id)} isLoading={false} />
				)}
			</MenuList>
		</div>
	);
};
