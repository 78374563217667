import { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Tab, Tabs } from '@mui/material';
import {
	RadabankOperationAccount,
	RadabankOperationBudget,
	RadabankOperationCard,
	RadabankSectionHeader,
} from 'components/RadabankCard';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { useGoBackRadabank } from 'utils/hooks';
import { FirebaseAnalytics } from 'utils/services';
import { TABS } from './helpers';
import styles from './index.module.scss';

export const RadabankOperationPage: FC = () => {
	const { search, pathname } = useLocation();

	const history = useHistory();

	const [activeTab, setActiveTab] = useState(() => {
		const params = new URLSearchParams(search);

		const initialTab = params.get('tab');

		return initialTab ? Number(initialTab) : TABS.CARD;
	});

	const handleClickBack = useGoBackRadabank();

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.OPERATION,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		handleClickBack();
	};

	return (
		<div className="page">
			<RadabankSectionHeader onClickBack={handleGoBack} title="На інші картки або рахунки" />
			<Tabs
				className={styles.tabs}
				value={activeTab}
				onChange={(_, value) => {
					FirebaseAnalytics.logEvent(
						FIREBASE_EVENT_ANALYTICS_PAGE.RADABANK.OPERATION,
						`${FIREBASE_EVENT_ANALYTICS_BUTTON.CHANGE_TAB}-${TABS[value]}`
					);
					setActiveTab(value);
					history.replace({ pathname, search: '' });
				}}
			>
				<Tab label="На картку" value={TABS.CARD} />
				<Tab label="На рахунок" value={TABS.ACCOUNT} />
				<Tab label="До бюджету" value={TABS.BUDGET} />
			</Tabs>
			<div className={styles.content}>
				{activeTab === TABS.CARD && <RadabankOperationCard />}
				{activeTab === TABS.ACCOUNT && <RadabankOperationAccount />}
				{activeTab === TABS.BUDGET && <RadabankOperationBudget />}
			</div>
		</div>
	);
};
