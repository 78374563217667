import { Capacitor } from '@capacitor/core';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { customHistory } from 'utils/constants';
import { ERROR_MESSAGE, ROUTER_URL, STORAGE_KEY } from 'utils/enums';
import { checkIsSessionError, getDeviceFP, getUserSession, redirectNotAuthorizedUser } from 'utils/helpers';
import { SessionsService, StorageService } from 'utils/services';
import { IErrorResponse, ISession } from 'utils/types';

const axiosConfig: AxiosRequestConfig = {
	baseURL: process.env.REACT_APP_USER_API_URL,
	withCredentials: true,
};

const instance: AxiosInstance = axios.create(axiosConfig);
// eslint-disable-next-line prefer-const
let controller: AbortController | undefined = new AbortController();

instance.interceptors.request.use(
	async (config: InternalAxiosRequestConfig) => {
		// for each request adding a device fingerprint and session id
		let deviceFP: string | null = await StorageService.get<string>(STORAGE_KEY.DEVICE_FP);
		if (!deviceFP) {
			deviceFP = await getDeviceFP();
		}

		const sessionId: string | null = await StorageService.get(STORAGE_KEY.SESSION_ID);

		// eslint-disable-next-line no-param-reassign
		config.data = config.data || {};
		// eslint-disable-next-line no-param-reassign
		config.data.deviceFP = deviceFP;

		if (sessionId) {
			// eslint-disable-next-line no-param-reassign
			config.data.sessionId = sessionId;
		}

		if (Capacitor.isNativePlatform()) {
			// console.log('config of request :', config?.data);
		}
		return { ...config, signal: controller?.signal };
	},

	(error: AxiosError<IErrorResponse>) => {
		throw error;
	}
);

instance.interceptors.response.use(
	async (res: AxiosResponse) => {
		if (Capacitor.isNativePlatform()) {
			// console.log('result of request :', res.data);
		}
		return res;
	},
	async (error: AxiosError<IErrorResponse>) => {
		if (Capacitor.isNativePlatform()) {
			// console.log('error  in request:', error);
		}
		if (axios.isCancel(error)) {
			// in redux middleware handle it and not throwing the error
			throw new Error(ERROR_MESSAGE.ABORT_MESSAGE);
		}

		const originalRequest = (error as AxiosError).config;

		if (checkIsSessionError(error) && originalRequest) {
			cancelAllRequests();
			enableAllRequests();
			try {
				const sessionResponse: Pick<ISession, 'sessionId'> = await getUserSession();
				await StorageService.set(STORAGE_KEY.SESSION_ID, sessionResponse.sessionId);

				if (sessionResponse.sessionId) {
					await SessionsService.checkSession();
				}
			} finally {
				await redirectNotAuthorizedUser();

				// eslint-disable-next-line no-unsafe-finally, prefer-promise-reject-errors
				return Promise.reject('');
				// eslint-disable-next-line no-unsafe-finally
				// return Promise.reject(
				// 	originalRequest.url === USER_API_URL.SESSION_CHECK
				// 		? ''
				// 		: new Error('Сесію не знайдено. Спробуйте увійти знову')
				// );
			}
		}

		if ((error as any)?.code === 'ERR_NETWORK' || !axiosConfig.baseURL) {
			customHistory.replace(ROUTER_URL.ERROR);
		}

		return Promise.reject(error);
	}
);

export const cancelAllRequests = (): void => {
	(controller as AbortController).abort(ERROR_MESSAGE.ABORT_MESSAGE);
};

export const enableAllRequests = (): void => {
	controller = new AbortController();
};

export const USER_API = instance;
