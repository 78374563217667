import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { addApartment, getApartmentList } from 'store/bill/actions';
import { getApartmentsSelector, getPossibleApartmentsLoadingSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { getUserProfileSelector } from 'store/user-service/selectors';
import {
	AddApartmentByAddress,
	AddApartmentById,
	AddApartmentByProviderId,
	AddApartmentVariant,
} from 'components/Apartment';
import ApartmentsChoiceStep from 'components/Apartment/AddApartmentByAddress/ApartmentsChoiceStep';
import { ArrowBackButton, CustomButton } from 'components/shared';
import { APARTMENTS_MAX_COUNT, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ADD_APARTMENT_VARIANT, ERROR_MESSAGE, ROUTER_URL } from 'utils/enums';
import { flatApartmentAccountIds, getAddAppartmentDto, getApartmentInitialValues, getAppartmentErrorMessage } from 'utils/helpers';
import { useKeyPress } from 'utils/hooks';
import { BillService } from 'utils/services';
import { IAddApartmentAccountRequest, IAddApartmentRequest } from 'utils/types';
import styles from './index.module.scss';

enum ADD_ADDRESS_STEP {
	VARIANT,
	ADDRESS_DATA,
	APARTMENTS_LIST,
}

export const AddAddressPage: FC = () => {
	const dispatch = useAppDispatch();

	const history = useHistory();

	const submitButtonRef = useRef<HTMLIonButtonElement>(null);

	const billApartments = useAppSelector(getApartmentsSelector);
	const profile = useAppSelector(getUserProfileSelector);
	const isReceiptLoading = useAppSelector(getPossibleApartmentsLoadingSelector);

	const [step, setStep] = useState(ADD_ADDRESS_STEP.VARIANT);
	const [apartmentVariant, setApartmentVariant] = useState<null | ADD_APARTMENT_VARIANT>(null);
	const [data, setData] = useState<IAddApartmentRequest>({
		userId: '',
	});
	const [isValid, setIsValid] = useState(false);
	const [isAddAddressDisabled, setIsAddAddressDisabled] = useState(true);
	const [apartmentIds, setApartmentIds] = useState<number[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (profile && !billApartments.list.length) {
			handleGetApartmentsList(profile.userId);
		}
	}, [profile]);

	useEffect(() => {
		if (isValid && submitButtonRef.current) {
			submitButtonRef.current.scrollIntoView({
				behavior: 'smooth',
			});
		}
	}, [submitButtonRef.current, isValid]);

	useEffect(() => {
		let isAddAddressDisabledPayload = isAddAddressDisabled;
		if (billApartments.list.length === APARTMENTS_MAX_COUNT) {
			isAddAddressDisabledPayload = true;
		} else {
			isAddAddressDisabledPayload = step === ADD_ADDRESS_STEP.VARIANT ? apartmentVariant === null : !isValid;
		}
		setIsAddAddressDisabled(isAddAddressDisabledPayload);
	}, [step, apartmentVariant, isValid, billApartments.list.length]);

	useEffect(() => {
		if (billApartments.list.length === APARTMENTS_MAX_COUNT) {
			dispatch(showToast({ message: ERROR_MESSAGE.APARTMENTS_MAX_COUNT }));
		}
	}, [billApartments.list.length]);

	useEffect(() => {
		if (profile) {
			setData(getApartmentInitialValues(profile.userId, apartmentVariant));
			setIsValid(false);
		}
	}, [apartmentVariant, profile]);

	const handleGetApartmentsList = async (userId: string): Promise<void> => {
		try {
			await dispatch(getApartmentList(userId)).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getAppartmentErrorMessage(error) }));
		}
	};

	const handleAddAddress = async (address: IAddApartmentAccountRequest) => {
		try {
			setIsLoading(true);
			await dispatch(addApartment(address)).unwrap();
			history.push(ROUTER_URL.RECEIPT_MAIN);
		} catch (error) {
			dispatch(showToast({ message: getAppartmentErrorMessage(error) }));
		} finally {
			setIsLoading(false);
		}
	};

	const handleSubmit = async (): Promise<void> => {
		if (isAddAddressDisabled || !apartmentVariant) return;
		switch (step) {
			case ADD_ADDRESS_STEP.ADDRESS_DATA:
				try {
					setIsLoading(true);
					const dto = getAddAppartmentDto(apartmentVariant, data);

					if (!dto) return;

					const { data: response } = await BillService.getApartmentAccountIdsList(dto);

					const responseApartmentIds = flatApartmentAccountIds(response);

					if (!responseApartmentIds.length) {
						dispatch(showToast({ message: ERROR_MESSAGE.APARTMENT_NOT_FOUND }));
						return;
					}

					if (responseApartmentIds.length > 1) {
						setApartmentIds(responseApartmentIds);
						setStep(ADD_ADDRESS_STEP.APARTMENTS_LIST);
						return;
					}
					await handleAddAddress({ userId: data.userId, apartmentAccountId: responseApartmentIds[0] });
				} catch (error) {
					dispatch(showToast({ message: getAppartmentErrorMessage(error) }));
				} finally {
					setIsLoading(false);
				}
				break;
			default:
				if (data.apartmentAccountId) {
					await handleAddAddress({ userId: data.userId, apartmentAccountId: data.apartmentAccountId });
				}
		}
	};

	const handleSubmitOnEnter = useKeyPress(handleSubmit);

	const handleClickBack = (): void => {
		if (step === ADD_ADDRESS_STEP.VARIANT) {
			history.goBack();
		} else {
			setApartmentVariant(null);
			setStep(ADD_ADDRESS_STEP.VARIANT);
		}
	};

	const content = useMemo(() => {
		if (step === ADD_ADDRESS_STEP.VARIANT || apartmentVariant === null) {
			return (
				<AddApartmentVariant
					value={apartmentVariant}
					onChange={(value) => {
						setApartmentVariant(value);
						setStep(ADD_ADDRESS_STEP.ADDRESS_DATA);
					}}
					pageName={FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.ADD_ADDRESS}
				/>
			);
		}
		const props = {
			data,
			onChange: (value: IAddApartmentRequest) => setData(value),
			setIsValid: (value: boolean) => setIsValid(value),
			onSubmit: handleSubmitOnEnter,
		};
		if (step === ADD_ADDRESS_STEP.APARTMENTS_LIST) {
			const onChangeApartmentId = (apartmentAccountId?: number) => setData((prev) => ({ ...prev, apartmentAccountId }));
			return (
				<ApartmentsChoiceStep
					onChange={onChangeApartmentId}
					apartmentAccountId={data.apartmentAccountId}
					apartmentIds={apartmentIds}
				/>
			);
		}
		switch (apartmentVariant) {
			case ADD_APARTMENT_VARIANT.APARTMENT_ACCOUNT_ID:
				return <AddApartmentById {...props} />;

			case ADD_APARTMENT_VARIANT.PROVIDER_ACCOUNT_ID:
				return <AddApartmentByProviderId {...props} />;

			default:
				return <AddApartmentByAddress {...props} />;
		}
	}, [apartmentVariant, data, step, isAddAddressDisabled]);

	const isApartmentListStep = step === ADD_ADDRESS_STEP.APARTMENTS_LIST;

	return (
		<div className={classNames('page', styles.wrapper)}>
			<div className={classNames(styles.content, { [styles.receipt]: isApartmentListStep })}>
				<ArrowBackButton className={styles.button_back} onClick={handleClickBack} />
				{content}
				{step !== ADD_ADDRESS_STEP.VARIANT && !isReceiptLoading && (
					<div className={styles.button_submit}>
						{isApartmentListStep && (
							<Typography mb="8px" textAlign="center" variant="subtitle2" color="var(--color-neutral-300)">
								Також ви можете звернутися до нашого центру клієнтської підтримки для керування цими даними
							</Typography>
						)}
						<CustomButton
							size="large"
							className={styles.button_submit}
							ref={submitButtonRef}
							label="Додати адресу"
							onClick={handleSubmit}
							isLoading={isLoading}
							disabled={isAddAddressDisabled}
						/>
					</div>
				)}
			</div>
		</div>
	);
};
