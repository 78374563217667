import { FC } from 'react';
import { FormNumberInput } from 'components/shared';
import { FormCustomAutocomplete } from 'components/shared/FormAutocomplete';
import { ISelectOption } from 'utils/types';
import { AdditionalIncomeSourceContainer } from './Container';

interface SurveyAdditionalIncomeProps {
	isLoading: boolean;
	sourceIncomeOptions?: ISelectOption[];
	scopeEnterpriseOptions?: ISelectOption[];
}

export const SurveyAdditionalIncome: FC<SurveyAdditionalIncomeProps> = ({
	isLoading,
	scopeEnterpriseOptions,
	sourceIncomeOptions,
}) => {
	return (
		<>
			<FormNumberInput
				name="amount_add"
				label="Додатковий прибуток(гривень)"
				inputProps={{ inputMode: 'decimal', autoComplete: 'off' }}
			/>
			<AdditionalIncomeSourceContainer>
				<FormCustomAutocomplete
					name="source_income"
					label="Джерело додаткового прибутку"
					options={sourceIncomeOptions}
					loading={isLoading}
				/>
				<FormCustomAutocomplete
					name="scope_enterprise"
					label="Галузь економіки"
					options={scopeEnterpriseOptions}
					loading={isLoading}
				/>
			</AdditionalIncomeSourceContainer>
		</>
	);
};
