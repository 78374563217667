import { App, AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { WEB_VERSION } from 'utils/constants';

export const getAppVersion = async (): Promise<string> => {
	let env = '';
	let app: AppInfo | null = null;

	if (Capacitor.isNativePlatform()) {
		app = await App.getInfo();
	}

	if (process.env.REACT_APP_URL?.includes('dev')) {
		env = 'development';
	} else if (process.env.REACT_APP_URL?.includes('sandbox')) {
		env = 'sandbox';
	} else {
		env = 'production';
	}

	const versionString = app
		? `v${app.version} (${app.build})`
		: `v${WEB_VERSION.version} (24.01.2025)`;
	return `${versionString} - ${env}`;
};
